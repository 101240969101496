var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      staticClass: "content",
      attrs: {
        autocomplete: "on",
        action: "javascript:;",
        method: "post",
        novalidate: ""
      },
      on: {
        submit: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.emitSubmit($event)
        }
      }
    },
    [
      _vm.infoKey
        ? _c("info", { staticClass: "info theme-bg-light theme-border" }, [
            _vm._v("\n    " + _vm._s(_vm.$t(_vm.infoKey)) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c("recaptcha", {
        on: {
          verified: function($event) {
            return _vm.$emit("errors", [])
          }
        }
      }),
      _vm._v(" "),
      _vm.info2Key
        ? _c(
            "info",
            {
              staticClass: "info theme-bg-light theme-border",
              attrs: { v2: "" }
            },
            [
              _c("h2", [_vm._v(_vm._s(_vm.$t(_vm.info2Key + ".title")))]),
              _vm._v(" "),
              _vm.info2callback
                ? [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t(_vm.info2Key + ".text1")) +
                        "\n      "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "theme-color",
                        attrs: { href: "javascript:;" },
                        on: { click: _vm.info2callback }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t(_vm.info2Key + ".link")) +
                            "\n      "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t(_vm.info2Key + ".text2")) +
                        "\n    "
                    )
                  ]
                : [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t(_vm.info2Key + ".text")) +
                        "\n    "
                    )
                  ]
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !!_vm.errors.length
        ? _c("error-note", { attrs: { "error-list": _vm.errors } })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("lower")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }