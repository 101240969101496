import Vue from 'vue';
import VueRouter from 'vue-router';
import { sync } from 'vuex-router-sync';

import env from 'env';
import Login from './components/login/Login';
import localStorage from './services/localStorage';
import store from './store';

const Page404 = () => import('./components/Page404');
const Recovery = () => import('./components/recovery/Recovery');
const Message = () => import('./components/Message');
const TwoFactor = () => import('./components/twoFactor/TwoFactor');
const RecoveryCode = () => import('./components/recovery/RecoveryCode');
const NewPassword = () => import('./components/newPassword/NewPassword');
const LoginList = () => import('./components/LoginList');
const BankId = () => import('./components/bankID/BankID.vue');
const BankIdLogin = () => import('./components/bankID/BankIDLogin.vue');
const Developer = () => import('./components/developer/Developer');
const Dashboard = () => import('./components/developer/Dashboard');
const State = () => import('./components/developer/State');
const Terms = () => import('./components/login/Terms');
const Forbidden = () => import('./components/login/Forbidden');

const routes = [
  { path: '/', name: 'login', component: Login, props: { account: 'standard', step: 'username' } },
  { path: '/email', name: 'email', component: Login, props: { account: 'email' } },
  { path: '/terms', name: 'terms', component: Terms },
  { path: '/forbidden', name: 'forbidden', component: Forbidden },
  { path: '/recovery/username', component: Recovery, props: { usecase: 'username' } },
  { path: '/recovery/password', component: Recovery, props: { usecase: 'password' } },
  { path: '/message/email', component: Message, props: { usecase: 'email' } },
  { path: '/message/sms', component: Message, props: { usecase: 'sms' } },
  { path: '/code/recover', component: RecoveryCode },
  { path: '/code/2step', component: TwoFactor },
  { path: '/password', name: 'new-password', component: NewPassword },
  { path: '/list', name: 'list', component: LoginList },
  { path: '/bankid', name: 'bankid', component: BankId },
  { path: '/bankid-login/:orderRef', name: 'bankid-login', component: BankIdLogin },
  {
    path: '/developer', component: Developer, children:
      [
        { path: "", component: Dashboard },
        { path: "state", component: State },
      ],
  },
  { path: '*', component: Page404 },
];

export const router = new VueRouter({
  mode: 'history',
  base: env.base,
  routes,
});
// sync router state into vuex store
sync(store, router);

export const routerUtils = {
  setQueryParams(queryParams) {
    router.onReady(() => {
      // force router to update url
      router.push({ path: router.currentRoute.path, query: Object.assign({}, router.currentRoute.query, queryParams) });
    });
  },
  removeQueryParam(...queryParams) {
    router.onReady(() => {
      const query = Object.assign({}, router.currentRoute.query);
      queryParams.forEach(queryParam => {
        delete query[queryParam];
      });
      // force router to update url
      router.push({ path: router.currentRoute.path, query });
    });
  },
};

// keep query params on navigation
// infinit loop protection
let lastTransition = '';
router.beforeEach((to, from, next) => {
  const transition = from.fullPath + to.fullPath + JSON.stringify(from.query) + JSON.stringify(to.query);
  if (transition === lastTransition) {
    return next();
  }
  // clone query from previous route
  const query = Object.assign(from.query || {}, to.query);
  lastTransition = transition;
  const toWithQuery = Object.assign({}, to, { query });
  if (from.path === to.path) {
    router.replace(toWithQuery);
  } else {
    router.push(toWithQuery);
  }
  next();
});

// fixes some chache corner cases
// more info: https://blog.francium.tech/vue-lazy-routes-loading-chunk-failed-9ee407bbd58
router.onError(error => {
  // One forced reload only allowed for each build
  const reloadAllowed =
    env.buildNumber !== localStorage.getItem('reloadForcedByApp');
  if (/loading chunk \d* failed/i.test(error.message) && reloadAllowed) {
    localStorage.setItem('reloadForcedByApp', env.buildNumber);
    window.location.reload(true);
  }
});

router.afterEach(() => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
})

Vue.use(VueRouter);
