<template>
  <svg
    viewBox="-1 -1 25 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      stroke-width="2"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g
        id="Icon/Arrow"
        :class="{'theme-stroke': themed}"
        class="arrow"
        stroke="#AAADAD"
      >
        <g
          id="Arrow/Right"
          v-if="!left"
        >
          <polyline
            id="Shape"
            transform="translate(20.500000, 5.000000) scale(-1, 1) translate(-20.500000, -5.000000) "
            points="23 10 18 5 23 0"
          />
          <path
            id="Shape"
            d="M0,5 L17,5"
            transform="translate(11.500000, 5.500000) scale(-1, 1) translate(-11.500000, -5.500000) "
          />
        </g>
        <g
          id="Arrow/Left"
          v-else
        >
          <polyline
            id="Shape"
            points="5 10 0 5 5 0"
          />
          <path
            id="Shape"
            d="M0,5 L23,5"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "SvgArrow",
  props: {
    themed: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
