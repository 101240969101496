const service = {
  setItem,
  getItem,
  removeItem,
};

const cache = {};

export default service;

function setItem(key, value, useCache) {
  const parsed = JSON.stringify(value);
  localStorage.setItem(key, parsed);
  if (useCache) {
    cache[key] = value;
  }
}

function getItem(key) {
  if (cache[key] !== undefined) {
    return cache[key];
  } else {
    try {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    } catch (e) {
      // TODO: Notify sentry about this case
      localStorage.removeItem(key);
      return null;
    }
  }
}

function removeItem(key) {
  localStorage.removeItem(key);
  delete cache[key];
}

