var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "outdated-modal",
      staticClass: "outdated-modal",
      on: { click: _vm.clickOutside }
    },
    [
      _c("div", { staticClass: "grey-background" }),
      _vm._v(" "),
      _c("div", { staticClass: "dialogbox-wrapper" }, [
        _c(
          "div",
          { ref: "dialogbox-content", staticClass: "dialogbox-content" },
          [
            _c("div", { staticClass: "modal-img-wrap" }, [
              _c("img", {
                staticClass: "modal-img",
                attrs: { src: _vm.oldCompImage, alt: "computer" }
              })
            ]),
            _vm._v(" "),
            _c("h2", {
              domProps: {
                innerHTML: _vm._s(_vm.xss(_vm.$t("out-modal_title")))
              }
            }),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("out-modal_text")))]),
            _vm._v(" "),
            _c("div", { staticClass: "delimiter" }, [
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "text" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("out-modal_delimiter-text")))])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "browser-links" }, [
              _c(
                "a",
                {
                  staticClass: "browser-link left discreet-link",
                  attrs: {
                    href: "https://google.com/chrome/",
                    target: "_blank"
                  }
                },
                [
                  _c("img", { attrs: { src: _vm.chromeIcon, alt: "chrome" } }),
                  _vm._v(" "),
                  _vm._m(0)
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "browser-link right discreet-link",
                  attrs: {
                    href: "https://mozilla.org/firefox",
                    target: "_blank"
                  }
                },
                [
                  _c("img", { attrs: { src: _vm.ffIcon, alt: "firefox" } }),
                  _vm._v(" "),
                  _vm._m(1)
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close ml-auto mb-1 outdated-corner-close",
                attrs: {
                  "aria-label": _vm.$t("close"),
                  title: _vm.$t("close"),
                  type: "button"
                },
                on: {
                  click: function($event) {
                    return _vm.hide()
                  }
                }
              },
              [_vm._v("\n        ×\n      ")]
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("h3", [_vm._v("Google Chrome")]),
      _vm._v(" "),
      _c("span", [_vm._v("google.com/chrome")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("h3", [_vm._v("Firefox")]),
      _vm._v(" "),
      _c("span", [_vm._v("mozilla.org/firefox")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }