var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hr-delimiter" }, [
    _c("div", { staticClass: "line" }),
    _vm._v(" "),
    _c("div", { staticClass: "text" }, [
      _vm._v("\n    " + _vm._s(_vm.text) + "\n  ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "line" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }