<template>
  <div
    ref="outdated-modal"
    @click="clickOutside"
    class="outdated-modal"
  >
    <div class="grey-background" />
    <div class="dialogbox-wrapper">
      <div
        ref="dialogbox-content"
        class="dialogbox-content"
      >
        <div class="modal-img-wrap">
          <img
            :src="oldCompImage"
            class="modal-img"
            alt="computer"
          >
        </div>
        <h2 v-html="xss($t('out-modal_title'))" />
        <p>{{ $t("out-modal_text") }}</p>
        <div class="delimiter">
          <hr>
          <div class="text">
            <span>{{ $t('out-modal_delimiter-text') }}</span>
          </div>
        </div>
        <div class="browser-links">
          <a
            href="https://google.com/chrome/"
            target="_blank"
            class="browser-link left discreet-link"
          >
            <img
              :src="chromeIcon"
              alt="chrome"
            >
            <div class="text">
              <h3>Google&nbsp;Chrome</h3>
              <span>google.com/chrome</span>
            </div>
          </a>
          <a
            href="https://mozilla.org/firefox"
            target="_blank"
            class="browser-link right discreet-link"
          >
            <img
              :src="ffIcon"
              alt="firefox"
            >
            <div class="text">
              <h3>Firefox</h3>
              <span>mozilla.org/firefox</span>
            </div>
          </a>
        </div>

        <button
          :aria-label="$t('close')"
          :title="$t('close')"
          @click="hide()"
          type="button"
          class="close ml-auto mb-1 outdated-corner-close"
        >
          ×
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import oldCompImage from "client/images/Image_OldComp.svg";
import chromeIcon from "client/images/Images_Chrome.svg";
import ffIcon from "client/images/Images_Firefox.svg";
import { isOutdatedBrowser } from "../../utilities";
import xss from 'xss'

export default {
  name: "OutdatedModal",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      xss,
      oldCompImage, chromeIcon, ffIcon,
    }
  },
  mounted() {
    if (isOutdatedBrowser()) {
      this.show();
    }
  },
	methods: {
		show() {
			const el = this.$refs["outdated-modal"];
      el.className = el.className + ' show';
		},
		clickOutside(e) {
      const contentEl = this.$refs["dialogbox-content"];
      if (_contains(contentEl, e.target) || contentEl === e.target) {
        return;
      }
      this.hide();
		},
		hide() {
			const el = this.$refs["outdated-modal"];
      el.className = el.className.replace('show', '');
		},
	},
};

function _contains(parent, child) {
    while (child) {
        child = child.parentNode;
        if (child === parent) {
          return true;
        }
    }
    return false;
}
</script>

<style lang="scss" scoped>

.outdated-modal {
    height:100%;
    width:100%;
    top:0;
    left:0;
    z-index: 1000;
    position: fixed;
    display: none;

    &.show {
      display: block;

      @media (min-height: 47.5rem) { // 760px
        display: table;
      }
    }

		.grey-background{
			width:100%;
			height:100%;
			background-color:$modal-backdrop-color;
			opacity:0.7;
			z-index: 50;
			position: absolute;
		}

		.dialogbox-wrapper{
				z-index:1000;
				display: block;
				position:relative;
				text-align: center;
        max-height: 100%;
        overflow: scroll;
        height: 100%;

        @media (min-height: 47.5rem) { // 760px
          display: table-cell;
          vertical-align: middle;
          height: auto;
          max-height: 100%;
        }
		}

		.dialogbox-content{
        position: relative;
				border: $border;
				background-color: white;
				/* To center horizontally */
				display: inline-block;
        padding: 5rem;
        margin-top: 8rem;

        @media (max-width: 28.125rem) { // 450px
          padding: 2rem;
        }
		}
}

// modal content
h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.modal-img-wrap {
  width: 100%;
  height: 10em;
}

.modal-img {
  position: relative;
  width: 18rem;
  left: -2rem;
  top: -9rem;
}

.delimiter {
  margin-top: 3.625rem;
  position: relative;
  width: 100%;
  height: 2rem;

  hr {
    width: 90%;
    width: calc(100% - 3rem);
  }

  .text {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -0.5rem;
    left: 0;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    font-size: 0.75rem;

    span {
      background: white;
      padding: 0 3rem;
    }
  }
}

.browser-links {
  .browser-link {
    margin: 1.5rem 2rem 0;
    text-align: left;
    height: 3rem;
    display: inline-block;

    @media (max-width: 47.5rem) { // 760px
      width: 100%;
    }

    &.left {
      float: left;
    }

    &.right {
      float: right;

      @media (max-width: 47.5rem) { // 760px
        float: left;
      }
    }

    img {
      margin-right: 1rem;
      width: 3rem;
      height: 3rem;
      vertical-align: middle;
    }

    .text {
      vertical-align: middle;
      display: inline-block;

      h3 {
        margin: 0;
      }
    }

    &:hover {
      text-decoration: none;

      span {
        text-decoration: underline;
      }
    }
  }
}

button.close {
  color: $text-color-light;
  text-shadow: none;
  font-weight: normal;
  font-size: 2rem;
  position: absolute;
  right: 1rem;
  top: 0.5rem;

  &:hover {
    color: $text-color-helper;
  }
}
</style>
