var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition-group", { attrs: { name: "height", mode: "out-in" } }, [
    _c(
      "div",
      { key: "wrapper-fix" },
      [
        _c(
          "ws-float-label",
          { key: "labeled-input", attrs: { label: _vm.$attrs.placeholder } },
          [
            _c(
              "b-form-input",
              _vm._g(
                _vm._b(
                  {
                    ref: "input",
                    class: { appended: !_vm.hideicon, exposed: _vm.exposed },
                    attrs: {
                      value: _vm.value,
                      autocomplete: _vm.autocomplete,
                      type: _vm.exposed ? "text" : "password",
                      spellcheck: "false",
                      size: "lg",
                      autocapitalize: "off"
                    },
                    on: { keydown: _vm.detectCapsLock, blur: _vm.hideTooltip }
                  },
                  "b-form-input",
                  _vm.$attrs,
                  false
                ),
                _vm.listeners
              )
            ),
            _vm._v(" "),
            !_vm.hideicon && _vm.value
              ? _c("div", { staticClass: "icon-wrap" }, [
                  _c("img", {
                    staticClass: "icon password-toggle-visibility",
                    attrs: {
                      src: _vm.exposed ? _vm.detailCrossedIcon : _vm.detailIcon,
                      alt: "expose"
                    },
                    on: {
                      click: function($event) {
                        return _vm.toggleExpose()
                      }
                    }
                  })
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-tooltip",
          {
            key: "tooltip",
            attrs: {
              show: _vm.showCapsAlert,
              disabled: _vm.hideCapsAlert,
              target: _vm.getTarget,
              placement: "top"
            },
            on: {
              "update:show": function($event) {
                _vm.showCapsAlert = $event
              },
              "update:disabled": function($event) {
                _vm.hideCapsAlert = $event
              }
            }
          },
          [
            _vm._v("\n      " + _vm._s(_vm.$t("capslock.line1")) + "\n      "),
            _c("br"),
            _vm._v("\n      " + _vm._s(_vm.$t("capslock.line2")) + "\n    ")
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }