<template>
  <div class="footer">
    <hr />
    <div class="note">
      <h4>{{ $t("have_trouble") }}</h4>
      <p>
        {{ $t("footer.part1") }}
        <a
          :href="resolveCompanyLink('knowledge')"
          class="theme-color"
        >{{ $t("footer.part2") }}</a>
        {{ $t("footer.part3") }}
      </p>
    </div>
    <div class="links">
      <div>
        <ws-form-select
          @input="langChange"
          v-model="selected"
          :key="selected.value"
          :options="options"
          no-border
          dropup
        />
      </div>
      <div class="links-wrapper capitalize">
        <a :href="resolveCompanyLink('knowledge')" target="_blank" class="discreet-link">
          {{ $t('help') }}
        </a>
        <a :href="resolveCompanyLink('terms')" target="_blank" class="discreet-link">
          {{ $t("terms") }}
        </a>
        <a :href="resolveCompanyLink('privacy')" target="_blank" class="discreet-link">
          {{ $t("privacy") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { WsFormSelect } from "WS_UIkit";
import i18nService from "../../services/i18n";
import langs from "../../../lang/langs";
import { config } from "../../services/config";
import { resolveCompanyLink } from "../../utilities";
import xss from 'xss'

const languages = config.company && config.company.languages;

let options;
if (languages) {
  options = languages.map(langValue => {
    return { value: langValue, text: langs[langValue] };
  });
} else {
  // fallback
  options = Object.entries(langs).map(entry => {
    return { value: entry[0], text: entry[1] };
  });
}

let subscription;

export default {
  name: "AppFooter",
  components: { WsFormSelect },
  data() {
    return {
      selected:
        options.filter(option => option.value === i18nService.i18n.locale)[0] ||
        options[0],
      options,
      xss,
      resolveCompanyLink,
    };
  },
  mounted() {
    subscription = i18nService.langChanges.subscribe(lang =>
      this.selectByLang(lang)
    );
  },
  destroyed() {
    subscription && subscription.unsubscribe();
  },
  methods: {
    langChange: selected => i18nService.setLang(selected.value),
    selectByLang: function(lang) {
      const found = this.options.filter(option => option.value === lang)[0];
      if (found) {
        this.selected = found;
      }
    },
  },
};
</script>


<style lang="scss">
@import "../../../scss/utilities";
</style>

<style lang="scss" scoped>
.note {
  margin: 2.5rem 0;
}

.links {
  display: flex;
  justify-content: space-between;
  color: $text-color-light;
  margin-top: 1rem;

  ::v-deep .btn.dropdown-toggle {
    padding-left: 0;
  }

  .links-wrapper {
    display: flex;
    align-items: center;

    a {
      color: $text-color-light;

      &:not(:first-of-type) {
        margin-left: 1rem;
      }
    }
  }
}
</style>

