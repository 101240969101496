// all client errors are coming trough this service, so you can
// possibly send them to sentry or collect to for analytics.. etc..

import Vue from 'vue';
import env from 'env';
import i18nService from './i18n';
import store from './../store';

// src: https://docs.sentry.io/platforms/javascript/vue/
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

export const INAPP_ERROR_FLAG = '#ws_app_internal';

const service = {
  standardApiErrorProcess,
  processError,
  parseError,
  logError,
  setEnv,
  setContext,
  hasError,
};

let sentryCfg = {
  dsn:
    'https://948912a125b74e99b4ad3309396d3beb@sentry.websupport.sk/6',
  integrations(integrations) {
    if (env.isProductionBuild) {
      // because vue integration supresses console logs in development
      // source: https://github.com/getsentry/sentry-javascript/issues/1416#issuecomment-445052188
      integrations.push(new Integrations.Vue({ Vue, attachProps: true }));
    }
    return integrations;
  },
  environment: env.isProductionBuild ? 'production' : 'development',
  release: env.buildNumber,
  ignoreErrors: [
    // JS error caused by an Outlook crawler, floods Sentry every night
    "Non-Error promise rejection captured",
  ],
}
Sentry.init(sentryCfg);
// DEV: test sentry by throwing any error
// throw new Error('example error to test sentry');

let t = () => {}; // t = translate method extracted from i18t-vue to local variable
i18nService.afterInit.then(() => {
  t = i18nService.i18n.t.bind(i18nService.i18n);
});

function processError(err) {

  let errParsed = parseError(err);
  let errorMessages = errParsed && errParsed.violations || [];

  if (!errParsed) {
    errorMessages = [
      {
        tKey: offlineCase() ? 'offline_error' : 'unknown_error',
      },
    ];
  } else if (errorMessages.length) {
    // transform violation messages
    errorMessages = errorMessages.map(violation => {
      const translation = _getTranslation(violation.type);
      if (!translation) {
        // did not find translation, use title
        violation.translation = violation.title;
      } else {
        violation.tKey = violation.type;
      }
      return violation;
    });
  } else if(errParsed.type === INAPP_ERROR_FLAG) {
    errorMessages = errParsed.errorMessages;
  } else {
    // error by type
    if (errParsed.type) {
      let tKey = 'error_api' + errParsed.type;
      let translation = _getTranslation(tKey);
      if (translation) {
        errorMessages = [
          {
            tKey,
          },
        ];
      }
    } else if (errParsed.message && errParsed.message.slice(0, 5) === 'tKey:') {
      let tKey = errParsed.message.slice(5);
      errorMessages = [
        {
          tKey,
        },
      ];
    }
    // general error by status
    if (!errorMessages.length) {
      let tKey = 'error_status_' + errParsed.status;
      let translation = errParsed.status && _getTranslation(tKey);
        errorMessages = translation ? [
          {
            tKey,
          },
        ]
        // did not find general status error translation
        : null;
    }
  }

  if (!errorMessages) {
    errorMessages = offlineCase();
    if (!errorMessages) {
      // not handled errors goes here
      errorMessages = [t('unknown_error')];
    }
    if (!env.isProductionBuild) {
      // eslint-disable-next-line no-console
      console.error(err);
    } else {
      // ensure sentry is notified
      logError(err);
    }
  }

  return errorMessages;
}

function parseError(err) {
  if (!err || err.type === INAPP_ERROR_FLAG) {
    return err;
  }
  const data = err.response && err.response.data;
  return { ...data, status: err.response && err.response.status, message: err.message };
}

function _getTranslation(tKey) {
  const translation = t(tKey);
  return translation !== tKey ? translation : null;
}

function logError(value) {
  setContext(store.state);

  if (typeof value === 'string') {
    // to get stack trace in Sentry logs we need Error object not just string
    // credit: https://forum.sentry.io/t/javascript-unhandledrejection-timeout/6917/2
    throw new Error(value);
  }
  Sentry.captureException(value);
}

function setEnv(env) {
  sentryCfg.environment = env;
}


function setContext(context) {
  Sentry.configureScope((scope) => {
    scope.setUser({"username": context.login});
    Object.keys(context).forEach(key => {
      scope.setExtra(key, context[key]);
    });
  });
}

function standardApiErrorProcess(ctrl) {
 return err => {
    ctrl.loading = false;
    ctrl.errors = processError(err);
  }
}

function offlineCase() {
  if (env.isBrowser && !navigator.onLine) {
    return 'offline_error';
  }
  // else
  return;
}

function hasError(errors, type) {
  return errors.some(err => err.type === type);
}

export default service;