import Axios from 'axios';
import env from 'env';
import recaptcha from './recaptcha';
import errorService from './error';

const service = {
  checkName,
  login,
  logout,
  recoveryLogin,
  recoveryPassword,
  setNewPassword,
  verifyPassCode,
  verify2faCode,
  switch2fa,
  bankIdAuth,
  bankIdCollect,
};

const axios = Axios.create({
  baseURL: env.apiUrl,
  withCredentials: true,
});

function checkName(login, account) {
  return recaptcha
    .execute('lookup', (reCaptchaToken, V2) =>
      axios
        .get('/lookup', {
          params: {
            login,
            reCaptchaToken,
            reCaptchaV2: V2,
            userType: account,
          },
        })
        .then(resp => resp.data)
    );
}

function login(data) {
  return recaptcha.execute('login', (token, V2) => {
    data.reCaptchaToken = token;
    data.reCaptchaV2 = V2;
    return axios.post('/login', data).then(resp => {
      const locationHeader = resp.headers && resp.headers.location;
      if (locationHeader) {
        return Object.assign({ redirectTo: locationHeader }, resp.data);
      } // else
      errorService.logError(new Error('Missing Location header. login:' + data.login));
      return resp.data;
    });
  });
}

function recoveryLogin(params) {
  return recaptcha.execute('recovery/login', (token, V2) => {
    params.reCaptchaToken = token;
    params.reCaptchaV2 = V2;
    return axios.post('/recovery/login', params).then(resp => resp.data);
  });
}

function recoveryPassword(params) {
  return recaptcha.execute('recovery/password', (token, V2) => {
    params.reCaptchaToken = token;
    params.reCaptchaV2 = V2;
    return axios.post('/recovery/password', params).then(resp => resp.data);
  });
}

function verifyPassCode(data) {
  return axios.put('/recovery/password/validation', data).then(resp => resp.data);
}

function setNewPassword(data, code) {
  if (!code) {
    return Promise.reject(new Error('Recovery code is missing.'));
  }
  return recaptcha.execute('password', (token, V2) => {
    data.reCaptchaToken = token;
    data.reCaptchaV2 = V2;
    return axios
      .put('/recovery/password/' + code, data)
      .then(resp => resp.data);
  });
}

function switch2fa(provider) {
  return axios.post('/2fa', { provider });
}

async function verify2faCode(data, provider) {
  await switch2fa(provider);
  return axios.post('/2fa/validate', data).then(_resolveLocationHeader);
}

// Bank ID
function bankIdAuth(data) {
    return axios.post('/bankid/auth', data);
}

function bankIdCollect(data) {
    return axios.post('/bankid/collect', data);
}


function logout() {
  return axios.get('../logout');
}

function _resolveLocationHeader(resp) {
  const locationHeader = resp.headers && resp.headers.location;
  if (locationHeader) {
    return Object.assign({ redirectTo: locationHeader }, resp.data);
  } // else
  return resp.data;
}

export default service;
