<script>
import Vue from 'vue';
import { BToaster, ToastPlugin } from 'bootstrap-vue';
Vue.use(ToastPlugin);

export const WsToaster = {
  name: "WsToaster",
  extends: BToaster,
};
export default WsToaster;
</script>

<style lang="scss">
@import "bootstrap/scss/toasts";
@import "bootstrap-vue/dist/bootstrap-vue";
</style>
<style lang="scss" scoped>
  .ws-toaster-bottom-right {
    position: fixed;
		right: 1.5rem;
		bottom: 1.5rem;

		@media (max-width: 400px) {
			right: 0.2rem;
			left: 0.2rem;
			bottom: 0.5rem;
		}
  }
</style>
