var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info" },
    [
      _c(
        "svg",
        {
          staticClass: "info__icon",
          attrs: {
            viewBox: "0 0 42 42",
            version: "1.1",
            xmlns: "http://www.w3.org/2000/svg",
            "xmlns:xlink": "http://www.w3.org/1999/xlink"
          }
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Symbols",
                stroke: "none",
                "stroke-width": "1",
                fill: "none",
                "fill-rule": "evenodd"
              }
            },
            [
              _c("g", { attrs: { id: "icon/question" } }, [
                _c("g", { attrs: { id: "Group-2" } }, [
                  _c("polygon", {
                    staticClass: "theme-fill",
                    attrs: {
                      id: "Polygon",
                      fill: "#82BD03",
                      points:
                        "21 0 39.1865335 10.5 39.1865335 31.5 21 42 2.81346652 31.5 2.81346652 10.5"
                    }
                  }),
                  _vm._v(" "),
                  _vm.v2
                    ? _c(
                        "g",
                        {
                          attrs: {
                            id: "Group-4",
                            transform: "translate(19.000000, 10.000000)",
                            fill: "#FFFFFF"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              id: "Combined-Shape",
                              d:
                                "M2.76488129,17.9116347 L4.11481022,19.6996834 L2.37017407,22.1399267 L0.105779892,20.9093876 L0.375001993,18.4782446 L2.76488129,17.9116347 Z M5.02481944,0 L3.60377737,15.4625961 L0.732875731,16 L0,0.0702846321 L5.02481944,0 Z",
                              transform:
                                "translate(2.512410, 11.069963) scale(1, -1) translate(-2.512410, -11.069963) "
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.v2
                    ? _c(
                        "g",
                        {
                          attrs: {
                            id: "Group-4",
                            transform: "translate(19.000000, 10.000000)",
                            fill: "#FFFFFF"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              id: "Combined-Shape",
                              d:
                                "M2.76488129,17.9116347 L4.11481022,19.6996834 L2.37017407,22.1399267 L0.105779892,20.9093876 L0.375001993,18.4782446 L2.76488129,17.9116347 Z M5.02481944,0 L3.60377737,15.4625961 L0.732875731,16 L0,0.0702846321 L5.02481944,0 Z",
                              transform:
                                "translate(2.512410, 11.069963) scale(1, -1) translate(-2.512410, -11.069963) "
                            }
                          })
                        ]
                      )
                    : _c(
                        "g",
                        {
                          attrs: {
                            id: "Group-4",
                            transform: "translate(13.000000, 10.000000)",
                            fill: "#FFFFFF"
                          }
                        },
                        [
                          _c("polygon", {
                            attrs: {
                              id: "Path-3",
                              points:
                                "0 6.59974534 3.57382781 7.19067867 4.3512553 4.42363844 8.36547059 3.94859431 10.832741 6.73993767 9.27573656 10.2942899 5.05413848 11.897515 5.70805629 17 8.57895793 16.4625961 8.57895793 13.8272304 11.8489339 12.9012108 15 6.59974534 10.5349937 0 1.88183538 1.47272276"
                            }
                          }),
                          _vm._v(" "),
                          _c("polygon", {
                            attrs: {
                              id: "Path-5",
                              transform:
                                "translate(6.828427, 20.828427) rotate(-45.000000) translate(-6.828427, -20.828427) ",
                              points:
                                "6.7378736 18.8284271 4.82842712 20.3571361 5.55947301 22.8284271 8.51862969 22.3365586 8.82842712 20.1176733"
                            }
                          })
                        ]
                      )
                ])
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }