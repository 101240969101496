var render, staticRenderFns
import script from "./WsToast.vue?vue&type=script&lang=js&"
export * from "./WsToast.vue?vue&type=script&lang=js&"
import style0 from "./WsToast.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./WsToast.vue?vue&type=style&index=1&id=5c97e37e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c97e37e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5c97e37e')) {
      api.createRecord('5c97e37e', component.options)
    } else {
      api.reload('5c97e37e', component.options)
    }
    
  }
}
component.options.__file = "client/WS_UIkit/WsToast.vue"
export default component.exports