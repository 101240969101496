<template>
  <img
    :src="mojeIDIcon"
    @click="login"
    alt="MojeID"
    class="mojeid"
  />
</template>

<script>
import iconCS from "client/images/mojeid/login-cs.svg";
import config from '../../services/config';
import i18nService from "../../services/i18n";

export default {
  name: "MojeIDLoginButton",
  data() {
    return {
      mojeIDIcon: iconCS,
    };
  },
  mounted() {
    this.setButton(i18nService.i18n.locale)
    i18nService.langChanges.subscribe(this.setButton);
  },
  methods: {
    login() {
      window.location = config.mojeId.loginUrl;
    },
    async setButton(lang) {
      const supportedLang = ['en', 'cs'].includes(lang) ? lang : 'en';

      this.mojeIDIcon =
          (await import(`client/images/mojeid/login-${ supportedLang }.svg`))?.default
          ||
          iconCS;
    },
  },
};
</script>

<style lang="scss">
img.mojeid {
  display: block;
  height: 42px;
  margin: 0 auto;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }
  &:active {
    opacity: 1;
    filter: brightness(0.9);
  }
}
</style>