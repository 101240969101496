var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "note" }, [
      _c("h4", [_vm._v(_vm._s(_vm.$t("have_trouble")))]),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n      " + _vm._s(_vm.$t("footer.part1")) + "\n      "),
        _c(
          "a",
          {
            staticClass: "theme-color",
            attrs: { href: _vm.resolveCompanyLink("knowledge") }
          },
          [_vm._v(_vm._s(_vm.$t("footer.part2")))]
        ),
        _vm._v("\n      " + _vm._s(_vm.$t("footer.part3")) + "\n    ")
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "links" }, [
      _c(
        "div",
        [
          _c("ws-form-select", {
            key: _vm.selected.value,
            attrs: { options: _vm.options, "no-border": "", dropup: "" },
            on: { input: _vm.langChange },
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "links-wrapper capitalize" }, [
        _c(
          "a",
          {
            staticClass: "discreet-link",
            attrs: {
              href: _vm.resolveCompanyLink("knowledge"),
              target: "_blank"
            }
          },
          [_vm._v("\n        " + _vm._s(_vm.$t("help")) + "\n      ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "discreet-link",
            attrs: { href: _vm.resolveCompanyLink("terms"), target: "_blank" }
          },
          [_vm._v("\n        " + _vm._s(_vm.$t("terms")) + "\n      ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "discreet-link",
            attrs: { href: _vm.resolveCompanyLink("privacy"), target: "_blank" }
          },
          [_vm._v("\n        " + _vm._s(_vm.$t("privacy")) + "\n      ")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }