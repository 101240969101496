<template>
  <div
    v-if="isLastest"
  >
    <div
      id="recaptcha-container"
    />
  </div>
</template>

<script>
import recaptcha from '../services/recaptcha';

const shared = { latestId: 0 };
let subscription;

export default {
  name: "Recaptcha",
  data() {
    return {
      id: ++shared.latestId,
      shared,
    };
  },
  computed: {
    isLastest() {
      return this.id === this.shared.latestId;
    },
  },
	mounted() {
		if (subscription) {
			subscription.unsubscribe();
		}
		subscription = recaptcha.captchaV2Changes.subscribe(
			() => this.$emit('verified'),
			err => this.$emit('error', err)
		);
	},
	destroyed() {
		if (subscription) {
			subscription.unsubscribe();
		}
		if (this.id === this.shared.latestId) {
			recaptcha.destroyV2();
		}
	},
};
</script>

<style lang="scss">
// https://stackoverflow.com/questions/27713505/recaptcha-api-v2-styling

#recaptcha-container {
  display: flex;
  justify-content: center;

	& > div {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
}
</style>