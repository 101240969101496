<template>
  <div
    :class="{visible: !hide}"
    class="form-label-group"
  >
    <slot />
    <label v-if="!hide">{{ label }}</label>
  </div>
</template>

<script>
export const WsFloatLabel = {
  name: "WsFloatLabel",
  props: {
    label: {
      type: String,
      default: "",
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
};
export default WsFloatLabel;
</script>

<style lang="scss">
</style>
<style lang="scss" scoped>
@mixin floated-label {
  padding-top: 1.4rem;
  padding-bottom: 0.4rem;
  font-size: 0.875rem;

  & ~ label {
    padding-top: 0.4rem;
    font-size: 0.75rem;
  }
}

.form-label-group {
  position: relative;

  &.visible {
    label {
      color: $floating-label;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      line-height: 1;
      z-index: 20;
      display: block;
      width: 100%;
      font-size: 0.875rem;
      margin-bottom: 0; /* Override default `<label>` margin */
      padding: $input-padding-y-lg $input-padding-x-lg;
      pointer-events: none;
      cursor: text; /* Match the input under the label */
      border: $border-width solid transparent;
      transition: all 0.1s ease-in-out;
    }

    ::v-deep input::placeholder {
      color: transparent;
    }

    // following rules need to be separated to work in FF
    ::v-deep input:focus {
      @include floated-label;
    }
    ::v-deep input:not(:placeholder-shown){
      @include floated-label;
    }
    ::v-deep input:-webkit-autofill{
      @include floated-label;
    }
    ::v-deep input:-moz-autofill {
      @include floated-label;
    }
    ::v-deep input:-moz-autofill-preview {
      @include floated-label;
    }

    /* Fallback for Edge
-------------------------------------------------- */
    @supports (-ms-ime-align: auto) {
      ::v-deep input {
        @include floated-label;
      }
    }

    /* Fallback for IE
-------------------------------------------------- */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      ::v-deep input {
        @include floated-label;
      }
    }

    ::v-deep .form-control-lg {
      height: $input-height-lg;
    }
  }
}
</style>
