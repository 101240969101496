<template>
  <div
    :class="{ 'page--content-only': !$slots.header && !$slots.footer }"
    class="page"
  >
    <header v-if="$slots.header">
      <slot name="header" />
    </header>
    <main>
      <slot />
    </main>
    <footer v-if="$slots.footer">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Page',
};
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 5rem);
  margin: 2.5rem 0;

  main {
    margin: 4rem 0;
  }
}

.page--content-only {
  justify-content: center;
}
</style>