<template>
  <div class="row account-switch">
    <div
      v-if="standard"
      class="img col-2"
    >
      <img
        :src="iconMail"
        alt="mail icon"
      />
    </div>

    <div
      :class="standard ? 'col-10 standard' : 'col-12'"
      class="body"
    >
      <div class="title">
        <h3>
          {{
            $t(
              standard ? "account.go_email.title" : "account.go_standard.title"
            )
          }}
        </h3>
      </div>
      <div
        v-if="!standard"
        class="text"
      >
        {{ $t("account.go_standard.text") }}
      </div>
      <div class="link">
        <a
          @click="setValue(!standard ? 'standard' : 'email')"
          href="javascript:;"
          class="theme-color"
        >
          {{
            $t(standard ? "account.go_email.link" : "account.go_standard.link")
          }}
          <svg-arrow themed />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { routerUtils } from "../../router";

import SvgArrow from "../svg/SvgArrow";
import iconMail from "client/images/mail.svg";

export default {
  name: "AccountSwitch",
  components: {
    SvgArrow,
  },
  data() {
    return { iconMail };
  },
  computed: {
    ...mapState(["account"]),
    standard() {
      return this.account === "standard";
    },
  },
  methods: {
    setValue(account) {
      this.$store.commit("updateState", { account });
      routerUtils.setQueryParams({ account });
    },
  },
};
</script>

<style lang="scss" scoped>
.account-switch {
  > .img > img {
    width: 50px;
  }
  > .body {
    &.standard {
      padding-left: 20px;

      > .title > h3 {
        margin: 0;
      }
    }
    > .text {
      margin-bottom: 1rem;
    }
    > .link {
      font-weight: bold;
      > a > svg {
        height: 0.7rem;
        width: auto;
        max-width: 2rem;
      }
    }
  }
}
</style>
