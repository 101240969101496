<!--
Attention!!! For omitting extra needs for mapping names between fields, references (ref="xy") and process steps -> names share
same values, keep it in mind in future changes ;)
-->
<template>
  <div>
    <ws-toast
      :visible="visible"
      class="cookies-notice"
      no-auto-hide
      no-fade
      no-close-button
      toaster="ws-toaster-bottom-right"
    >
      <div slot="toast-title">
        <h2
          v-html="xss($t('cookie_banner.title'))"
          class="white"
        />
        <button
          :aria-label="$t('close')"
          :title="$t('close')"
          @click="onClose()"
          type="button"
          class="close ml-auto mb-1 cookies-corner-close"
        >
          ×
        </button>
      </div>
      <p class="text">
        {{ $t('cookie_banner.text') }}
      </p>
      <div class="flex">
        <a
          :href="resolveCompanyLink('cookies')"
          class="link discreet-link"
        >{{ $t('cookie_banner.link') }}</a>
        <ws-button
          @click="onClose()"
          class="action-button theme-bg uppercase cookies-button-close"
          size="lg"
          variant="primary"
        >
          <span>{{ $t('close') | sentence(!site || site === 'admin') }}</span>
        </ws-button>
      </div>
    </ws-toast>
    <ws-toaster name="ws-toaster-bottom-right" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { WsToaster, WsToast, WsButton } from "WS_UIkit";
import { resolveCompanyLink } from "../../utilities";
import cookies from "../../services/cookies";

import xss from 'xss'

const COOKIE_NAME = "cookiesNoticeRead";

export default {
  name: "CookiesBanner",
  components: {
    WsToaster,
    WsToast,
    WsButton,
  },
  data() {
    const hasCookie = cookies.getCookie(COOKIE_NAME);

    return {
      xss,
      visible: !hasCookie,
      resolveCompanyLink,
    };
  },
  computed: mapState(["site", "company"]),
  methods: {
    onClose() {
      cookies.setCookie(COOKIE_NAME, 1, "never");
      this.$bvToast.hide();
    },
  },
};
</script>

<style lang="scss">
@import "bootstrap/scss/toasts";
@import "bootstrap-vue/dist/bootstrap-vue";
</style>
<style lang="scss" scoped>
h2.white {
  color: $white;
  padding-right: 1.5rem;
}

button.close {
  color: $text-color-helper;
  text-shadow: none;
  font-weight: normal;
  font-size: 1.7rem;
  position: absolute;
  right: 0;
  top: -0.5rem;

  &:hover {
    color: $white;
  }
}

.text {
  margin: 0;
}

.flex {
  -ms-flex: 0 1 auto; // IE fix
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;

  .link {
    margin-top: 0.5rem;
  }

  ::v-deep .action-button {
    margin-top: 0.5rem;

    @media (max-width: 400px) {
      width: 100%;
    }
  }
}
</style>