import base from './env.base';
import { buildNumber } from './buildNumber';

export default Object.assign(
  {
    buildNumber,
    isProductionBuild: true,
  },
  base
);
