<template>
  <div class="header">
    <router-link
      v-once
      to="/?step=username"
      class="logo-anchor"
    >
      <img
        :src="logoImgSrc"
        :class="['logo-' + companyCode]"
        class="logo"
        alt="Logo"
      />
    </router-link>
    <img
      :src="badgeImgSrc"
      class="badge"
      alt="badge"
    />
  </div>
</template>

<script>
import {mapState} from "vuex";
import logoWs from "client/images/ws-logo-full.svg";
import logoWb from "client/images/wb-logo-full.svg";
import logoA24 from "client/images/a24-logo-full.svg";
import hdBadge from "client/images/Components_Baddge_Helpdesk.svg";
import adminBadge from "client/images/Components_Baddge_Admin.svg";
import settingsBadge from "client/images/Components_Baddge_Settings.svg";
import webBadge from "client/images/Components_Baddge_Web.svg";


export default {
  name: "AppHeader",
  props: {
    site: { type: String, default: () => "admin" },
  },
  data() {
    return {
      logoImgSrc: null,
    };
  },
  computed: {
    ...mapState(["company", "account", 'isHelpdesk']),
    badgeImgSrc() {
      var site = this.isHelpdesk ? 'helpdesk' : this.site;
      return {
        helpdesk: hdBadge,
        admin: this.account === "email" ? settingsBadge : adminBadge,
        web: webBadge,
        cart: webBadge,
      }[site || "admin"];
    },
    companyCode() {
      let company = "ws";

      if (this.company) {
        let companyId = this.company ? this.company.id : null;
        switch (companyId) {
            case "cz-a24":
            case "we-a24":
                company = "a24";
                break;
            default:
                company = "ws";
        }
      }

      return company;
    },
  },
  beforeMount() {
    this.logoImgSrc = this.resolveLogo();
  },
  methods: {
    resolveLogo() {
      let companies = {
        wb: logoWb,
        ws: logoWs,
        a24: logoA24,
      };

      let company = this.companyCode;

      return companies[company] ? companies[company] : companies["ws"];
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;

  .logo-anchor {
    -ms-flex: 0 1 auto; // IE fix
    margin-right: 1.5rem;

    .logo {
      height: 3rem;
      width: auto;
    }

    .logo-a24 {
      height: 2.5rem;
    }
  }

  .badge {
    -ms-flex: 0 1 auto; // IE fix
    flex: 0 1 auto;
    height: 1.5rem;
    width: auto;
  }
}
</style>

