var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ws-toast",
        {
          staticClass: "cookies-notice",
          attrs: {
            visible: _vm.visible,
            "no-auto-hide": "",
            "no-fade": "",
            "no-close-button": "",
            toaster: "ws-toaster-bottom-right"
          }
        },
        [
          _c("div", { attrs: { slot: "toast-title" }, slot: "toast-title" }, [
            _c("h2", {
              staticClass: "white",
              domProps: {
                innerHTML: _vm._s(_vm.xss(_vm.$t("cookie_banner.title")))
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close ml-auto mb-1 cookies-corner-close",
                attrs: {
                  "aria-label": _vm.$t("close"),
                  title: _vm.$t("close"),
                  type: "button"
                },
                on: {
                  click: function($event) {
                    return _vm.onClose()
                  }
                }
              },
              [_vm._v("\n        ×\n      ")]
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("cookie_banner.text")) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "a",
                {
                  staticClass: "link discreet-link",
                  attrs: { href: _vm.resolveCompanyLink("cookies") }
                },
                [_vm._v(_vm._s(_vm.$t("cookie_banner.link")))]
              ),
              _vm._v(" "),
              _c(
                "ws-button",
                {
                  staticClass:
                    "action-button theme-bg uppercase cookies-button-close",
                  attrs: { size: "lg", variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.onClose()
                    }
                  }
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("sentence")(
                          _vm.$t("close"),
                          !_vm.site || _vm.site === "admin"
                        )
                      )
                    )
                  ])
                ]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("ws-toaster", { attrs: { name: "ws-toaster-bottom-right" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }