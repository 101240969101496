<!--
Attention!!! For omitting extra needs for mapping names between fields, references (ref="xy") and process steps -> names share
same values, keep it in mind in future changes ;)
-->
<template>
  <div
    :class="'frame--' + themeColor"
    class="container frame"
  >
    <div class="row frame__row">
      <!-- <div class="col-md-8"> -->
      <div class="col frame__col temp">
        <page class="left">
          <template #header>
            <app-header v-bind="{ site }" />
          </template>

          <!-- content -->
          <router-view />

          <template #footer>
            <app-footer />
          </template>
        </page>
      </div>
    </div>
    <cookies-banner />
    <outdated-modal />
    <!-- Hack to get rid of warning: "The resource http://domain.tld/build/images/Icon_alert-poly.svg was preloaded
         using link preload but not used within a few seconds from the window's load event. Please make sure it has
    an appropriate `as` value and it is preloaded intentionally."-->
    <img
      :src="iconAlert"
      style="display: none"
      alt="hack"
    >
  </div>
</template>

<script>
import { mapState } from "vuex";
import iconAlert from "client/images/Icon_alert-poly.svg";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import Page from "./Page";
import CookiesBanner from "./CookiesBanner";
import OutdatedModal from "./OutdatedModal";
// import AdCarousel from "./AdCarousel";

export default {
  name: "AppFrame",
  components: {
    AppHeader,
    AppFooter,
    CookiesBanner,
    OutdatedModal,
    Page,
    // AdCarousel,
  },
  data() {
    return {
      iconAlert,
    };
  },
  computed: {
    ...mapState(["site", "isHelpdesk"]),
    themeColor() {
      var site = this.isHelpdesk ? 'helpdesk' : this.site
      return (
        { helpdesk: "hd", admin: "blue", web: "green", cart: "green" }[
          site
        ] || "blue"
      );
    },
  },
};
</script>

<style lang="scss">
@import "../../../scss/app";
@import "../../../scss/utilities";
@import "bootstrap/scss/type";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/close";
@import "bootstrap/scss/modal";

html {
  height: 100%;
}
body {
  height: 100%;
  font-size: 0.875rem; // commonly ~14px
}
</style>
<style lang="scss" scoped>
.frame {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin themed($color) {
  ::v-deep .theme-color {
    color: $color;
  }
  ::v-deep .theme-stroke {
    stroke: $color;
  }
  ::v-deep .theme-stop-color {
    stop-color: $color;
  }
  ::v-deep .theme-border {
    border-color: $color;
  }
  ::v-deep a.theme-color:hover {
    color: darken($color, 10%);
  }
  ::v-deep .theme-fill {
    fill: $color;
  }
  ::v-deep .theme-bg {
    background: $color;
  }
  ::v-deep .theme-bg-light {
    background: mix($color, white, 3%);
  }
  ::v-deep button.theme-bg:hover:not(:disabled),
  ::v-deep a.btn.theme-bg:hover:not(:disabled) {
    background: lighten($color, 5%);
  }
  ::v-deep button.theme-bg:active:not(:disabled),
  ::v-deep a.btn.theme-bg:active:not(:disabled) {
    background: darken($color, 5%);
  }
  // checkboxes
  ::v-deep .theme-color-checkbox {
    & .custom-control-input:checked ~ .custom-control-label {
      color: $color;

      &:before {
        border-color: $color;
        background-color: $color;
      }
      &:after {
        border: $border-width solid $color;
      }
    }
  }
}

.frame--blue {
  @include themed($blue);

// temporary explicit admin/blue button style as it will be
// different until web admin redesign will be done
  ::v-deep button.btn.theme-bg.btn-lg {
    font-family: $font-family-base;
    font-size: $font-size-base;
    font-weight: 500;
    padding: 0.75rem 2.6875rem;
    line-height: 1rem;
    letter-spacing: initial;
    text-transform: none;
  }
}

.frame--green {
  @include themed($green);
}

.frame--hd {
  @include themed($helpdesk);
}

::v-deep button.theme-bg:disabled {
  background: $text-color-light;
}

::v-deep
  .theme-color-checkbox
  .custom-control-input:checked
  ~ .custom-control-label:after {
  background-image: $custom-checkbox-indicator-icon-checked;
  // following svg is provided by designer, but I do not see any difference
  // background-image: url("/build/images/Icon_check.svg");
  background-size: cover;
}

.frame__row {
  height: 100%;
  // max-height: $max-height; // disabled max height as Peter (the designer) wished
}

// vertical rule
.frame__col:not(:last-child)::after {
  background: $lines-color;
  width: $border-width;
  content: "";
  display: block;
  position: absolute;
  top: 10%;
  bottom: 10%;
  right: 0;
  height: 80%;
  margin: 0 2rem;
}

.temp {
  // TODO: to be removed once right side will be finished
  display: flex;
  justify-content: left;
}

.left {
  max-width: 26rem;
  @media (max-width: 400px) {
    max-width: 100%;
  }
}

::v-deep header {
  min-height: 5rem;
}
</style>
