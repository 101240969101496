var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      { staticClass: "user-item", class: { disabled: _vm.disabled } },
      _vm.listeners
    ),
    [
      _c(
        "svg",
        {
          staticClass: "avatar",
          attrs: {
            viewBox: "0 0 100 100",
            version: "1.1",
            xmlns: "http://www.w3.org/2000/svg"
          }
        },
        [
          _c("defs", [
            _c(
              "pattern",
              {
                attrs: {
                  id: "img",
                  patternUnits: "userSpaceOnUse",
                  width: "100",
                  height: "100"
                }
              },
              [
                _c("image", {
                  attrs: {
                    "xlink:href": _vm.userIcon,
                    x: "-25",
                    width: "150",
                    height: "100"
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c("polygon", {
            attrs: {
              points: "50 1 95 25 95 75 50 99 5 75 5 25",
              fill: "url(#img)"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "username" },
        [
          _vm._v("\n    " + _vm._s(_vm.login) + "\n    "),
          _vm.additional
            ? [
                _c("br"),
                _vm._v(" "),
                _c("span", { staticClass: "additional" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t(_vm.additional)) + "\n      "
                  )
                ])
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "extra" }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }