var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "p",
    { staticClass: "bread-crumbs" },
    [
      _vm._l(_vm.options.crumbs, function(crumb, index) {
        return [
          _c(
            "span",
            { key: index, class: { active: index === _vm.options.active } },
            [
              _vm._v(
                _vm._s(index + 1) +
                  ". " +
                  _vm._s(_vm._f("sentence")(_vm.$t(crumb)))
              )
            ]
          ),
          _vm._v(" "),
          index !== _vm.last
            ? _c("span", {
                key: index + "_d",
                staticClass: "bread-crumbs__delimiter"
              })
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }