import Vue from 'vue';

export function sentenceFilter(value, condition) {
  if (!value) {
    return '';
  }
  if (condition !== undefined && !condition) {
    // do not change
    return value;
  }
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
}

Vue.filter('sentence', sentenceFilter);

Vue.filter('json', value => { return JSON.stringify(value, null, 2) });