var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page",
      class: { "page--content-only": !_vm.$slots.header && !_vm.$slots.footer }
    },
    [
      _vm.$slots.header ? _c("header", [_vm._t("header")], 2) : _vm._e(),
      _vm._v(" "),
      _c("main", [_vm._t("default")], 2),
      _vm._v(" "),
      _vm.$slots.footer ? _c("footer", [_vm._t("footer")], 2) : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }