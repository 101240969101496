var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row", attrs: { id: "webauthn-wrapper" } }, [
    _c(
      "div",
      { staticClass: "col-4", attrs: { id: "fingerprint-wrapper" } },
      [
        _c("svg-fingerprint", {
          staticStyle: { position: "absolute" },
          attrs: { "animate-success": _vm.animateSuccess }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-8 container" }, [
      _c("div", { staticClass: "row" }, [
        _c("h3", [_vm._v(_vm._s(_vm.$t("webauthn.title")))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("webauthn.continue")))])
      ]),
      _vm._v(" "),
      !_vm.hideAlternativeProcess
        ? _c("div", { staticClass: "row" }, [
            _c(
              "a",
              {
                staticClass: "theme-color",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$emit("alternative-process")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.alternativeProcessTitle))]
            )
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _vm.is2fa
          ? _c(
              "b-form-checkbox",
              {
                staticClass: "trusted-checkbox theme-color-checkbox",
                attrs: { value: true },
                on: {
                  change: function($event) {
                    return _vm.$emit("trusted-change")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("2step_sent.trust")) + "\n    ")]
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12", attrs: { id: "webauthn-button" } },
      [
        _c(
          "ws-button",
          {
            staticClass: "theme-bg uppercase",
            attrs: {
              disabled: !_vm.supportsWebauthn,
              size: "lg",
              variant: "primary"
            },
            on: {
              click: function($event) {
                return _vm.$emit("authenticate-clicked")
              }
            }
          },
          [_c("span", [_vm._v(_vm._s(_vm.$t("webauthn.authenticate")))])]
        )
      ],
      1
    ),
    _vm._v(" "),
    !_vm.supportsWebauthn
      ? _c(
          "div",
          { staticClass: "col-12", attrs: { id: "webauthn-not-supported" } },
          [_c("p", [_vm._v(_vm._s(_vm.webauthnNotFoundMessage))])]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }