<template>
  <div class="info">
    <svg
      class="info__icon"
      viewBox="0 0 42 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Symbols"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="icon/question">
          <g id="Group-2">
            <polygon
              id="Polygon"
              class="theme-fill"
              fill="#82BD03"
              points="21 0 39.1865335 10.5 39.1865335 31.5 21 42 2.81346652 31.5 2.81346652 10.5"
            />
            <g
              id="Group-4"
              v-if="v2"
              transform="translate(19.000000, 10.000000)"
              fill="#FFFFFF"
            >
              <path
                id="Combined-Shape"
                d="M2.76488129,17.9116347 L4.11481022,19.6996834 L2.37017407,22.1399267 L0.105779892,20.9093876 L0.375001993,18.4782446 L2.76488129,17.9116347 Z M5.02481944,0 L3.60377737,15.4625961 L0.732875731,16 L0,0.0702846321 L5.02481944,0 Z"
                transform="translate(2.512410, 11.069963) scale(1, -1) translate(-2.512410, -11.069963) "
              ></path>
            </g>
            <g
              id="Group-4"
              v-if="v2"
              transform="translate(19.000000, 10.000000)"
              fill="#FFFFFF"
            >
              <path
                id="Combined-Shape"
                d="M2.76488129,17.9116347 L4.11481022,19.6996834 L2.37017407,22.1399267 L0.105779892,20.9093876 L0.375001993,18.4782446 L2.76488129,17.9116347 Z M5.02481944,0 L3.60377737,15.4625961 L0.732875731,16 L0,0.0702846321 L5.02481944,0 Z"
                transform="translate(2.512410, 11.069963) scale(1, -1) translate(-2.512410, -11.069963) "
              ></path>
            </g>
            <g
              id="Group-4"
              v-else
              transform="translate(13.000000, 10.000000)"
              fill="#FFFFFF"
            >
              <polygon
                id="Path-3"
                points="0 6.59974534 3.57382781 7.19067867 4.3512553 4.42363844 8.36547059 3.94859431 10.832741 6.73993767 9.27573656 10.2942899 5.05413848 11.897515 5.70805629 17 8.57895793 16.4625961 8.57895793 13.8272304 11.8489339 12.9012108 15 6.59974534 10.5349937 0 1.88183538 1.47272276"
              />
              <polygon
                id="Path-5"
                transform="translate(6.828427, 20.828427) rotate(-45.000000) translate(-6.828427, -20.828427) "
                points="6.7378736 18.8284271 4.82842712 20.3571361 5.55947301 22.8284271 8.51862969 22.3365586 8.82842712 20.1176733"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
    <slot />
  </div>
</template>

<script>
export default {
  name: "Info",
  props: {
    v2: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  position: relative;
  color: $text-color-dark;
  font-weight: bolder;
  border: $border;
  padding: 1rem 2.5rem;
}

.info__icon {
  position: absolute;
  width: 3rem;
  left: -1.5rem;
  top: -1rem;
}
</style>
