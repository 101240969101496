const service = {
	setCookie,
	getCookie,
};

function setCookie(name, value, expires) {
	let cookie = name + '=' +  encodeURIComponent(value);
	if (expires) {
		if (expires === 'never') {
			expires = 'Fri, 31 Dec 9999 23:59:59 GMT'; // The maximum value compatible with 32 bits systems
		}
		cookie += ';expires=' + expires;
	}

	document.cookie = cookie;
}

function getCookie(name) {
	const cookies = document.cookie.split(';').filter((item) => item.trim().startsWith(name + '='));
	return cookies && cookies[0];
}

export default service;