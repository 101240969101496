var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("img", {
        staticClass: "badge",
        attrs: { src: _vm.badgeImgSrc, alt: "badge" }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "router-link",
      { staticClass: "logo-anchor", attrs: { to: "/?step=username" } },
      [
        _c("img", {
          staticClass: "logo",
          class: ["logo-" + _vm.companyCode],
          attrs: { src: _vm.logoImgSrc, alt: "Logo" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }