var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.errorList.length
    ? _c("div", { staticClass: "error-note" }, [
        _c("div", { staticClass: "icon d-xs-none" }, [
          _c("div", { staticClass: "hexagon" }, [
            _c("img", {
              staticClass: "hexagon__img",
              attrs: { src: _vm.iconAlert, alt: "alert icon" }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "message" }, [
          _c(
            "ul",
            { staticClass: "message__list" },
            _vm._l(_vm.errorList, function(error, index) {
              return _c("li", { key: index }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.xss(
                        (error && error.translation) ||
                          _vm.getTranslation(error) ||
                          _vm.$t("unknown_error")
                      )
                    )
                  }
                })
              ])
            }),
            0
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }