var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "policy" }, [
    _vm._v("\n  " + _vm._s(_vm.$t("recaptcha_policy.part1")) + "\n  "),
    _c(
      "a",
      {
        staticClass: "theme-color",
        attrs: { href: "https://policies.google.com/privacy?hl=" + _vm.lang }
      },
      [_vm._v(_vm._s(_vm.$t("recaptcha_policy.policy_link")))]
    ),
    _vm._v("\n  " + _vm._s(_vm.$t("recaptcha_policy.part2")) + "\n  "),
    _c(
      "a",
      {
        staticClass: "theme-color",
        attrs: { href: "https://policies.google.com/terms?hl=" + _vm.lang }
      },
      [_vm._v(_vm._s(_vm.$t("recaptcha_policy.terms_link")))]
    ),
    _vm._v("\n  " + _vm._s(_vm.$t("recaptcha_policy.part3")) + "\n")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }