<script>
import { BDropdown } from "bootstrap-vue";
export { BDropdownItem } from "bootstrap-vue";

export const WsDropdown = {
  name: "WsDropdown",
  extends: BDropdown,
};
export default WsDropdown;
</script>

<style lang="scss">
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/dropdown";
</style>
<style scoped>
button,
.dropdown-menu {
  width: 100%;
}

[no-border] button {
  color: inherit;
  background-color: transparent;
  border: none;
  font-family: inherit;
  text-align: left;
}
</style>

