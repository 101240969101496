import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Subject } from 'rxjs';

import env from 'env';
import { config } from '../services/config';
import DEFAULT_LANG_MESSAGES from '../../lang/en';
import langs from '../../lang/langs';
import { getQueryParams, provide } from '../utilities';

const RX_TWO_LETTER_LANG = /^\w{2}$/;
const RX_FOUR_LETTER_LANG = /^\w{2}-\w{2}$/;

Vue.use(VueI18n);

let i18n;
const service = {
  setLang,
  loadLanguageAsync,
  i18n,
  afterInit: undefined,
  langChanges: undefined,
  getLang,
};

const DEFAULT_LANG = 'en-US';

const supportedLanguages = config.company?.languages || Object.keys(langs);
const loadedLanguages = [DEFAULT_LANG]; // our default language that is preloaded

const langChanges = new Subject();
service.langChanges = langChanges.asObservable();
let initResolve;

service.afterInit = new Promise(resolve => {
  initResolve = resolve;
});

let queryParams = {};
let htmlLang = '';
if (env.isBrowser) {
  queryParams = getQueryParams(window.location.href);
  htmlLang = document.querySelector('html').getAttribute('lang');
}

// initialisation
let pickedLang =
  queryParams.l ||
  htmlLang ||
  _findSupportedLang(supportedLanguages, _detectBrowserLangs()) ||
  DEFAULT_LANG;

if (env.isBrowser) {
  document.querySelector('html').setAttribute('lang', pickedLang);
}

i18n = service.i18n = new VueI18n({
  locale: pickedLang,
  fallbackLocale: DEFAULT_LANG,
  messages: {
    [DEFAULT_LANG]: DEFAULT_LANG_MESSAGES,
  },
});

if (pickedLang !== DEFAULT_LANG) {
  loadLanguageAsync(pickedLang).then(() => {
    initResolve(service);
    setLang(pickedLang);
  });
} else {
  initResolve(service);
  setLang(pickedLang);
}

provide({i18nService: service});

export default service;
export { service, i18n };

// function definitions

function setLang(lang) {
  if (lang === 'cz') {
    // fixes incorrect usage of 'cz' as Czech lang abbreviation by some of our BEs.. as correct is 'cs'
    // ref: http://sentry-internal.websupport.sk/websupport/auth-service-js/issues/424886/
    lang = 'cs';
  }
  if (lang === i18n.locale) {
    return;
  }
  if (!_isSupportedLang(lang)) {
    lang = DEFAULT_LANG;
  }

  if (!loadedLanguages.includes(lang)) {
    loadLanguageAsync(lang).then(_setLang);
  } else {
    _setLang(lang);
  }
}

function _setLang(lang) {
  if (env.isBrowser) {
    document.querySelector('html').setAttribute('lang', lang);
  }
  i18n.locale = lang;
  langChanges.next(lang);
  return lang;
}

async function loadLanguageAsync(lang, retried = false) {
  try {
    const msgs = await import(/* webpackChunkName: "lang-[request]" */ `client/lang/${lang}`);
    // let actualMsgs = i18n.getLocaleMessage(lang);
    // i18n.setLocaleMessage(lang, Object.assign(msgs.default, actualMsgs));
    i18n.setLocaleMessage(lang, msgs.default);
    loadedLanguages.push(lang);
    return lang;
  } catch(err) {
    if(retried) {
      throw err;
    }
    return await loadLanguageAsync(lang, true);
  } 
}

function getLang() {
  return i18n.locale;
}

function _isSupportedLang(lang) {
  return supportedLanguages.indexOf(lang) >= 0;
}

function _findSupportedLang(supportedLanguages, languages) {
  let resultLang = '';
  const langLength = languages.length;
  const supLangLength = supportedLanguages.length;
  rootForLoop: for (let i = 0; i < langLength; i++) {
    const lang = languages[i];
    for (let ii = 0; ii < supLangLength; ii++) {
      const supLang = supportedLanguages[ii];
      if (!resultLang && supLang === lang) {
        resultLang = supLang;
        break rootForLoop;
      }
    }
  }
  return resultLang;
}

function _detectBrowserLangs() {
  let languages = navigator.languages && navigator.languages.concat();
  if (!languages) {
    languages = [navigator.language || navigator.userLanguage];
  } else {
    // normalize case
    languages = languages.map(lang => {
      if (lang.match(RX_TWO_LETTER_LANG)) {
        return lang.toLowerCase();
      }
      if (lang.match(RX_FOUR_LETTER_LANG)) {
        const parts = lang.split('-');
        return [parts[0].toLowerCase(), parts[1].toUpperCase()].join('-');
      }
      return lang;
    });
  }
  return languages.length ? languages : [];
}
