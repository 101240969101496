<template>
  <transition-group
    name="height"
    mode="out-in"
  >
    <!-- wrapper-fix reference: https://github.com/vuejs/vue/issues/8516 -->
    <div key="wrapper-fix">
      <ws-float-label
        key="labeled-input"
        :label="$attrs.placeholder"
      >
        <b-form-input
          ref="input"
          v-bind="$attrs"
          :value="value"
          v-on="listeners"
          :autocomplete="autocomplete"
          :type="exposed ? 'text' : 'password'"
          @keydown="detectCapsLock"
          @blur="hideTooltip"
          :class="{ appended: !hideicon, exposed }"
          spellcheck="false"
          size="lg"
          autocapitalize="off"
        />
        <div
          v-if="!hideicon && value"
          class="icon-wrap"
        >
          <img
            @click="toggleExpose()"
            :src="exposed ? detailCrossedIcon : detailIcon"
            class="icon password-toggle-visibility"
            alt="expose"
          />
        </div>
      </ws-float-label>
      <b-tooltip
        key="tooltip"
        :show.sync="showCapsAlert"
        :disabled.sync="hideCapsAlert"
        :target="getTarget"
        placement="top"
      >
        {{ $t("capslock.line1") }}
        <br />
        {{ $t("capslock.line2") }}
      </b-tooltip>
    </div>
  </transition-group>
</template>

<script>
import { BFormInput, BTooltip } from "bootstrap-vue";
import { WsFloatLabel } from "WS_UIkit";
import detailIcon from "client/images/Icon_detail.svg";
import detailCrossedIcon from "client/images/Icon_detail_cross.svg";

// implements transparent wrapper component described here: https://github.com/chrisvfritz/7-secret-patterns
// and also some inspiration from: https://zendev.com/2018/05/31/transparent-wrapper-components-in-vue.html
export default {
  name: "PasswordInput",
  components: {
    BFormInput,
    BTooltip,
    WsFloatLabel,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: String,
      default: "password",
    },
    hideicon: {
      type: Boolean,
      default: false,
    },
    expose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      exposed: false,
      exposeByClick: false,
      showCapsAlert: false,
      hideCapsAlert: true,
      detailIcon,
      detailCrossedIcon,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
      };
    },
  },
  watch: {
    expose(val) {
      this.exposed = val;
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    detectCapsLock(event) {
      this.showCapsAlert =
        event.getModifierState && event.getModifierState("CapsLock");
      this.hideCapsAlert = !this.showCapsAlert;
    },
    hideTooltip() {
      this.showCapsAlert = false;
      this.hideCapsAlert = !this.showCapsAlert;
    },
    getTarget() {
      return this.$refs.input;
    },
    toggleExpose(exposed) {
      if (exposed === undefined) {
        this.exposed = this.exposeByClick = !this.exposeByClick;
      } else if (!this.exposeByClick) {
        this.exposed = exposed;
      } else {
        return;
      }
      this.$emit("expose", this.exposed);
    },
  },
};
</script>

<style lang="scss">
@import "bootstrap/scss/forms";
@import "bootstrap/scss/tooltip";
</style>

<style lang="scss" scoped>
::v-deep .input-group-text {
  border-left: none;
  background: none;
}

::v-deep input.appended {
  padding-right: 3rem;
}

.icon-wrap {
  height: 100%;
  width: 0;
  .icon {
    cursor: pointer;
    height: 1.25rem;
    width: 1.25rem;
    position: relative;
    left: -2.25rem;
  }
}

::v-deep .form-label-group {
  display: flex;
  align-items: center;
  width: 100%;
  input {
    flex: 1 1 auto;
    width: 100%;
  }
}

// workaround fix for empty prepend span
::v-deep .input-group-prepend {
  margin-right: 0;
}

// Removing clear and reveal password icon from IE10
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
</style>