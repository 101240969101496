<template>
  <transition-group
    name="height"
    mode="out-in"
  >
    <div
      key="edit"
      class="input-wrap"
    >
      <ws-float-label
        :label="$attrs.label"
        :hide="!editMode"
      >
        <b-form-input
          ref="input"
          v-bind="$attrs"
          :value="value"
          v-on="listeners"
          v-show="editMode"
          autocomplete="username"
          autocapitalize="off"
          spellcheck="false"
          type="text"
          size="lg"
        />
      </ws-float-label>
    </div>
    <user-item
      key="saved"
      :login="value"
      v-show="!editMode"
    >
      <button
        @click="$emit('edit-start')"
        type="button"
        class="edit-btn capitalize username-input-edit-btn"
      >
        {{ $t("change")
        }}<img
          :src="pencilIcon"
          class="edit-icon"
          alt="icon edit"
        />
      </button>
    </user-item>
  </transition-group>
</template>

<script>
import { BFormInput } from "bootstrap-vue";
import { WsFloatLabel } from "WS_UIkit";
import pencilIcon from "client/images/Icon_pencil.svg";
import UserItem from "../UserItem";
// implements transparent wrapper component described here: https://github.com/chrisvfritz/7-secret-patterns
// and also some inspiration from: https://zendev.com/2018/05/31/transparent-wrapper-components-in-vue.html
export default {
  name: "UsernameInput",
  components: { BFormInput, WsFloatLabel, UserItem },
  inheritAttrs: false,
  props: {
    editMode: Boolean,
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pencilIcon,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
      };
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style lang="scss">
@import "bootstrap/scss/forms";
</style>

<style lang="scss" scoped>
.edit-btn {
  background: transparent;
  border: 0;
  padding: 0;
}

.edit-icon {
  width: 1rem;
  margin-left: 0.5rem;
}

::v-deep .user-item {
  margin-top: 1rem;
  border: $border;
  background-color: $disabled-bg-color;
}

// TODO: animations does not work yet as expected
// .height-enter-active,
// .height-leave-active {
//   transition: height 5s ease;
//   & > * {
//     position: absolute;
//     top: 0;
//     left: 0;
//   }
// }
// .height-enter, .height-leave-to
// /* .component-height-leave-active below version 2.1.8 */ {
//   height: 0;
//   overflow: hidden;
// }
</style>