import store from "./store";

export function syncStore(key) {
	return {
		[key]: {
			get() {
				return store.state[key]
			},
			set(value) {
				store.commit('updateState', {[key]: value})
			},
		},
	}
}
