<template>
  <svg
    viewBox="0 0 89 90"
    width="89px"
    height="90px"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>162BA2FB-21D3-4D5B-AF3E-54922BA5089D@1x</title>
    <defs>
      <linearGradient
        id="bottom-to-top"
        x2="0%"
        y1="100%"
        y2="0%"
      >
        <stop
          class="theme-stop-color"
          offset="0"
        >
          <animate
            ref="animationSuccess1"
            begin="indefinite"
            dur="0.5s"
            attributeName="offset"
            fill="freeze"
            from="0"
            to="1"
          />
        </stop>
        <stop
          offset="0"
          stop-color="#eef0f3"
        >
          <animate
            ref="animationSuccess2"
            begin="indefinite"
            dur="0.5s"
            attributeName="offset"
            fill="freeze"
            from="0"
            to="1"
          />
        </stop>

      </linearGradient>

      <path
        id="path-1"
        d="M52.7619814,23.9904804 L76.1267271,36.3330001 L84.2558325,57.2673819 L75.2873277,73.2569447 L57.0962457,69.653378 L52.6249984,57.844088 L42.5149984,53.393088 L35.4299984,59.408088 L42.8469984,80.223088 L58.5911548,84.1220365 L57.1489959,89.9461396 L38.2772742,85.2731419 L28.3868507,57.5171597 L41.4946627,46.3888948 L57.3456387,53.3666789 L61.5289984,64.415088 L72.1829984,66.525088 L77.6379984,56.800088 L71.3429984,40.592088 L51.4389984,30.077088 L28.8289984,31.394088 L9.09699842,49.635088 L11.8129138,73.8425045 L5.85031385,74.5113859 L2.79658083,47.2895373 L26.3342758,25.5302459 L52.7619814,23.9904804 Z M39.2990197,33.6950379 L63.8484664,41.7320591 L71.669558,58.6312033 L66.2244389,61.1512585 L59.4989984,46.621088 L40.4119984,40.373088 L24.0649984,52.723088 L25.3379984,68.961088 L35.1627118,85.5021106 L30.0040123,88.5660655 L19.4652776,70.8222775 L17.8278909,49.9163577 L39.2990197,33.6950379 Z M46.6919959,59.8237208 L51.8999984,71.636088 L73.6800508,77.1250274 L72.213946,82.9431487 L47.5931492,76.7389692 L41.202001,62.2444553 L46.6919959,59.8237208 Z M55.5254434,11.9742606 L75.9782725,21.3690766 L88.1844948,35.0356862 L83.7095021,39.0324899 L72.3369984,26.299088 L54.4719984,18.093088 L23.7979984,20.869088 L3.89399684,37.8497879 L1.91846539e-13,33.2850548 L21.3577017,15.0656277 L55.5254434,11.9742606 Z M39.7578917,1.19015908e-12 L61.140946,1.88334235 L80.0275902,11.4384286 L77.3189929,16.7922591 L59.4636658,7.75822586 L40.3501416,6.07439241 L17.5961127,12.6840545 L15.9227514,6.92212237 L39.7578917,1.19015908e-12 Z"
      ></path>
    </defs>
    <g
      id="Single-Sign-On"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="01-9-1---SSO---Password-less"
        transform="translate(-78.000000, -490.000000)"
      >
        <g
          id="Group"
          transform="translate(78.000000, 345.000000)"
        >
          <g
            id="Rectangle"
            transform="translate(0.000000, 145.000000)"
          >
            <mask
              id="mask-2"
              fill="#eef0f3"
            >
              <use xlink:href="#path-1"></use>
            </mask>
            <use
              id="Mask"
              :class="useClass"
              fill="url(#bottom-to-top)"
              fill-rule="nonzero"
              xlink:href="#path-1"
            ></use>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "SvgFingerprint",
  props: {
    color: {
      type: String,
      default: "#EEF0F3",
    },
    useClass: {
      type: String,
      default: "",
    },
    animateSuccess: {
      type: Boolean,
      default: false,
    },
    animateFailure: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    animateSuccess: function(val) {
      if (val === true) {
        this.animateSuccessSvg();
      }
    },
  },
  methods: {
    animateSuccessSvg() {
      this.$refs["animationSuccess1"].beginElement();
      this.$refs["animationSuccess2"].beginElement();
    },
  },
};
</script>
