<template>
  <div class="wrapper">
    <div
      v-if="link"
      class="extra"
    >
      <img
        v-if="link.icon"
        :src="link.icon.src"
        :alt="link.icon.alt"
      />
      <router-link
        v-if="link.route"
        :to="link.route"
        :class="['discreet-link-' + routeToClass(link.route), disabled ? 'disabled' : '']"
        class="discreet-link"
      >
        <slot name="route-icon" />{{ $t(link.tKey) }}
      </router-link>
      <a
        v-else-if="link.tKey"
        :href="link.href || 'javascript:;'"
        :class="'discreet-link-' + link.tKey"
        class="discreet-link"
      ><slot name="link-icon" />{{ $t(link.tKey) }}</a>
    </div>
    <ws-button
      v-if="showButton"
      :class="
        (link ? 'right-button' : 'full-button') +
        ' discreet-link-' +
        button.tKey
      "
      :disabled="disabled"
      @click="$emit('buttonClick')"
      class="theme-bg uppercase"
      size="lg"
      variant="primary"
      type="submit"
    >
      <b-spinner
        v-if="loading"
        :title="$t('loading')"
        small
      />
      <span v-else>{{
        $t(button.tKey) | sentence(!site || site === "admin")
      }}</span>
    </ws-button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { BSpinner } from "bootstrap-vue";
import { WsButton } from "WS_UIkit";

const SLASH_REGEXP = /\//g;

export default {
  name: "LinkAndButton",
  components: {
    WsButton,
    BSpinner,
  },
  props: {
    button: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    link: {
      type: Object,
      default: () => {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: mapState(["site"]),
  methods: {
    routeToClass(route) {
      return route.path
        ? route.path.replace(SLASH_REGEXP, "-")
        : "some-route-link";
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}

.extra {
  -ms-flex: 0 1 auto; // IE fix
  flex: 1 1 0;
  min-width: 0;
  margin-bottom: 1rem;
  margin-right: 1rem;
  height: 100%;
  display: flex;
  align-items: center;

  > img {
    flex: 0 1 auto;
    margin-right: 0.5rem;
    vertical-align: text-top;
    height: 1.1rem;
    width: auto;
  }

  > .discreet-link {
    flex: 1 1 auto;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

::v-deep .right-button {
  -ms-flex: 0 1 auto; // IE fix
  margin-bottom: 1rem;

  @media (max-width: 400px) {
    width: 100%;
  }
}

::v-deep .full-button {
  margin-bottom: 1rem;
  width: 100%;
}
</style>
