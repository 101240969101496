/* global grecaptcha */

import { Subject } from 'rxjs';
import env from 'env';
import { default as errorService, INAPP_ERROR_FLAG } from './error';
import i18nService from './i18n';

let attempts = 10;
let interval = 100;
const callbacks = [];

let ensureGrecaptcha = (callback) => {
  if (callback) {
    let pollingIsRunning = !!callbacks.length;
    callbacks.push(callback);
    if (pollingIsRunning) {
      // do not start parallel polling
      return;
    }
  }
  setTimeout(() => {
    if (attempts <= 0) {
      callbacks.length = 0; // clear
      throw new Error(`Google Rcaptcha unreachable`);
    }

    if (typeof grecaptcha !== 'undefined') {
      grecaptcha.ready(() => {
        // flush callbacks
        callbacks.forEach(callback => callback());
        callbacks.length = 0; // clear
      });
      // from now on, just run callbacks.. grecaptcha is ready
      ensureGrecaptcha = callback => callback();
    } else {
      attempts--;
      interval = interval * 2;
      ensureGrecaptcha();
    }

  }, interval);
}

const service = {
  setKeys,
  execute,
  renderV2,
  destroyV2,
  captchaV2Changes: null,
  setCaptchaLang,
};
const TOKEN_VALIDITY = 2 * 60 * 1000; // in ms
const RECAPTCHA_CONTAINER_ID = 'recaptcha-container';
const captchaV2Changes = new Subject();
service.captchaV2Changes = captchaV2Changes.asObservable();
const humanityCheckError = { type: INAPP_ERROR_FLAG, errorMessages: [{ tKey: 'verify_humanity', type: 'recaptcha' }] };

let key = '';
let keyV2 = '';
let tokenV2 = '';
let tokenValidityTimeout;
let v2rendered = null;
let forceV2reg = /forceReCaptchaV2/g;

i18nService.langChanges.subscribe(lang => setCaptchaLang(lang));

function setKeys(newKey, newKeyV2) {
  key = newKey;
  keyV2 = newKeyV2;
}

function execute(action, callback) {
  try {
    if (env.isBrowser && window.location.href.match(forceV2reg)) {
      return renderV2().then(token => callback(token, true));
    }
    // create real promise, because execute method does not return the real one
    // (missing documentation what actually returns)
    const promise = new Promise((resolve, reject) => {
      ensureGrecaptcha(() =>
        grecaptcha.execute(key, { action }).then(token => {
          resolve(token);
        },
          reject)
      );
    })
      .then(token => callback(token, false))
      .catch(err => {
        const parsedErr = errorService.parseError(err);

        if (parsedErr && parsedErr.type === 'reCaptcha') {
          return renderV2().then(token => callback(token, true));
        }
        // else rethrow error to next catches
        throw err;
      });
    return promise;
  } catch (e) {
    if (e === null) {
      return Promise.reject(new Error('reCaptcha.execute() error'));
    } // else
    return Promise.reject(e);
  }
}

function renderV2() {
  if (tokenV2) {
    const resolved = Promise.resolve(tokenV2);
    tokenV2 = ''; // one-time usage
    return resolved;
  } else if (v2rendered) {
    resetV2();
    return Promise.reject(humanityCheckError);
  }

  const promise = new Promise((resolve, reject) => {
    ensureGrecaptcha(() => {
      destroyV2(); // destroy if exist already
      v2rendered = grecaptcha.render(RECAPTCHA_CONTAINER_ID, {
        sitekey: keyV2,
        callback: token => {
          tokenV2 = token;
          clearTimeout(tokenValidityTimeout);
          tokenValidityTimeout = setTimeout(() => {
            tokenV2 = '';
          }, TOKEN_VALIDITY);
          captchaV2Changes.next(true);
        },
        'expired-callback': () => {
          resetV2();
        },
        'error-callback': err => {
          captchaV2Changes.error(err);
          reject(err);
        },
      });

    });
    setTimeout(() => {
      // setTimeout to wait for render to apply
      setCaptchaLang(i18nService.getLang());
    }, 0);
    reject(humanityCheckError);
  });



  return promise;
}

function destroyV2() {
  // we need to remove element and add back its clone to torn recaptcha references
  // otherwise you get error that element waas already used for recaptcha
  const container = document.getElementById(RECAPTCHA_CONTAINER_ID);
  if (!container) {
    return;
  }
  const clone = container.cloneNode();
  const parent = container.parentNode;
  parent.removeChild(container);
  parent.appendChild(clone);
  v2rendered = null;
}

function setCaptchaLang(lang) {
  if (!env.isBrowser || v2rendered === null) {
    return;
  }

  const container = document.getElementById(RECAPTCHA_CONTAINER_ID);

  // Get GoogleCaptcha iframe
  const iframeGoogleCaptcha = container.querySelector('iframe');

  // Get language code from iframe
  const actualLang = iframeGoogleCaptcha.getAttribute("src").match(/hl=(.*?)&/).pop();

  // For setting new language
  if (actualLang !== lang) {
    iframeGoogleCaptcha.setAttribute("src", iframeGoogleCaptcha.getAttribute("src").replace(/hl=(.*?)&/, 'hl=' + lang + '&'));
  }
}

function resetV2() {
  if (v2rendered === null) {
    return;
  }
  ensureGrecaptcha(() => {
    grecaptcha.reset(v2rendered);
    // setTimeout to wait for reset to apply
    setCaptchaLang(i18nService.getLang());
  });
}

export default service;
