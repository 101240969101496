var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dropdown",
    _vm._g(
      _vm._b(
        { attrs: { text: _vm.value.text } },
        "b-dropdown",
        _vm.$attrs,
        false
      ),
      _vm.listeners
    ),
    _vm._l(_vm.options, function(option, index) {
      return _c(
        "b-dropdown-item-button",
        {
          key: index,
          on: {
            click: function($event) {
              return _vm.select(index)
            }
          }
        },
        [_vm._v("\n    " + _vm._s(option.text) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }