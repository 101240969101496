import Vue from 'vue';
import Vuex from 'vuex';
import env from 'env';
import { mutations } from './mutations';
import { actions } from './actions';
import getInitialState from './initialState';

Vue.use(Vuex);

export const store = new Vuex.Store({
  strict: env.isProductionBuild,
  state: getInitialState(),
  actions,
  mutations,
});

export default store;