var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row account-switch" }, [
    _vm.standard
      ? _c("div", { staticClass: "img col-2" }, [
          _c("img", { attrs: { src: _vm.iconMail, alt: "mail icon" } })
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "body",
        class: _vm.standard ? "col-10 standard" : "col-12"
      },
      [
        _c("div", { staticClass: "title" }, [
          _c("h3", [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$t(
                    _vm.standard
                      ? "account.go_email.title"
                      : "account.go_standard.title"
                  )
                ) +
                "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        !_vm.standard
          ? _c("div", { staticClass: "text" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("account.go_standard.text")) +
                  "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "link" }, [
          _c(
            "a",
            {
              staticClass: "theme-color",
              attrs: { href: "javascript:;" },
              on: {
                click: function($event) {
                  return _vm.setValue(!_vm.standard ? "standard" : "email")
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      _vm.standard
                        ? "account.go_email.link"
                        : "account.go_standard.link"
                    )
                  ) +
                  "\n        "
              ),
              _c("svg-arrow", { attrs: { themed: "" } })
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }