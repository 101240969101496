var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container frame", class: "frame--" + _vm.themeColor },
    [
      _c("div", { staticClass: "row frame__row" }, [
        _c(
          "div",
          { staticClass: "col frame__col temp" },
          [
            _c(
              "page",
              {
                staticClass: "left",
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _c(
                          "app-header",
                          _vm._b({}, "app-header", { site: _vm.site }, false)
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "footer",
                    fn: function() {
                      return [_c("app-footer")]
                    },
                    proxy: true
                  }
                ])
              },
              [_vm._v(" "), _c("router-view")],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("cookies-banner"),
      _vm._v(" "),
      _c("outdated-modal"),
      _vm._v(" "),
      _c("img", {
        staticStyle: { display: "none" },
        attrs: { src: _vm.iconAlert, alt: "hack" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }