import Axios from 'axios';
import env from 'env';
import { cloneDeep } from "lodash";

class WebAuthnHelpers {
    static coerceToArrayBuffer(input) {
        if (typeof input === 'string') {
            // base64url to base64
            input = input.replace(/-/g, '+').replace(/_/g, '/');
            // base64 to Uint8Array
            const str = window.atob(input);
            const bytes = new Uint8Array(str.length);

            for (let i = 0; i < str.length; i++) {
                bytes[i] = str.charCodeAt(i);
            }

            input = bytes;
        }

        // Array to Uint8Array
        if (Array.isArray(input)) {
            input = new Uint8Array(input);
        }

        // Uint8Array to ArrayBuffer
        if (input instanceof Uint8Array) {
            input = input.buffer;
        }

        // error if none of the above worked
        if (!(input instanceof ArrayBuffer)) {
            throw new TypeError(`could not coerce '${name}' to ArrayBuffer`);
        }

        return input;
    }

    static coerceToBase64Url(input) {
        // Array or ArrayBuffer to Uint8Array
        if (Array.isArray(input)) {
            input = Uint8Array.from(input);
        }

        if (input instanceof ArrayBuffer) {
            input = new Uint8Array(input);
        }

        // Uint8Array to base64
        if (input instanceof Uint8Array) {
            let str = '';
            const len = input.byteLength;

            for (let i = 0; i < len; i++) {
                str += String.fromCharCode(input[i]);
            }

            input = window.btoa(str);
        }

        if (typeof input !== 'string') {
            throw new Error('could not coerce to string');
        }

        // base64 to base64url
        // NOTE: "=" at the end of challenge is optional, strip it off here
        input = input.replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=*$/g, '');

        return input;
    }
}

const axios = Axios.create({
    baseURL: env.apiUrl,
    withCredentials: true,
});

const getAssertionOptions = async (login = undefined) => {
    const payload = login !== undefined ? { login } : {};
    const response = await axios.post('/webauthn/options', payload);
    const original = cloneDeep(response.data);
    const data = {
        publicKey: { ...response.data },
    };
    data.publicKey.challenge = WebAuthnHelpers.coerceToArrayBuffer(data.publicKey.challenge);
    if (data.publicKey.allowCredentials !== undefined && data.publicKey.allowCredentials.length > 0) {
        data.publicKey.allowCredentials.map(credential => credential.id = WebAuthnHelpers.coerceToArrayBuffer(credential.id));
    }

    return { original: original, transformed: data };
};

const assertionToServer = (assertionResponse, assertionOptions) => {
    return {
        options: { ...assertionOptions },
        sign: {
            id: assertionResponse.id,
            rawId: WebAuthnHelpers.coerceToBase64Url(assertionResponse.rawId),
            response: {
                authenticatorData: WebAuthnHelpers.coerceToBase64Url(assertionResponse.response.authenticatorData),
                clientDataJSON: WebAuthnHelpers.coerceToBase64Url(assertionResponse.response.clientDataJSON),
                signature: WebAuthnHelpers.coerceToBase64Url(assertionResponse.response.signature),
                userHandle: assertionResponse.response.userHandle ? WebAuthnHelpers.coerceToBase64Url(assertionResponse.response.userHandle) : assertionResponse.response.userHandle,
            },
            type: "public-key",
        },
    };
};

const assertion = async (login = undefined) => {
    const { original, transformed } = await getAssertionOptions(login);
    const response = await navigator.credentials.get(transformed);

    return assertionToServer(response, original);
};

const supports = () => {
    return typeof PublicKeyCredential !== "undefined";
};

const PASSWORDLESS = 'passwordless';
const TWO_FACTOR = 'two_factor';

const webauthnService = {
    assertion,
    supports,
    PASSWORDLESS,
    TWO_FACTOR,
};

export default webauthnService;
