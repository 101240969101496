<template>
  <div
    v-on="listeners"
    :class="{ disabled }"
    class="user-item"
  >
    <svg
      class="avatar"
      viewBox="0 0 100 100"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <pattern
          id="img"
          patternUnits="userSpaceOnUse"
          width="100"
          height="100"
        >
          <image
            :xlink:href="userIcon"
            x="-25"
            width="150"
            height="100"
          />
        </pattern>
      </defs>
      <polygon
        points="50 1 95 25 95 75 50 99 5 75 5 25"
        fill="url(#img)"
      />
    </svg>
    <div class="username">
      {{ login }}
      <template v-if="additional">
        <br>
        <span class="additional">
          {{ $t(additional) }}
        </span>
      </template>
    </div>
    <div class="extra">
      <slot />
    </div>
  </div>
</template>

<script>
import userIcon from "client/images/Icon_user-unknown.svg";

export default {
  name: "UserItem",
  props: {
    login: {
      type: String,
      default: "",
    },
    additional: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userIcon,
    }
  },
  computed: {
    // map listeners
    listeners() {
      return {
        ...this.$listeners,
      };
    },
  },
};
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>

.user-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  width: 100%;
  height: 3.5rem;
  color: $text-color-dark;
  overflow: hidden;

  & > *:not(:first-child) {
    margin-left: 1rem;
  }

  &.disabled {
    opacity: 0.5;
    background: $disabled-bg-color;
    cursor: not-allowed;
  }
}

.avatar {
  width: 2rem;
  flex-shrink: 0;
}

.username {
  flex-grow: 1;
  font-weight: bolder;

  .additional {
    color: $text-color-light;
  }
}

.extra {
  flex-shrink: 0;
}
</style>