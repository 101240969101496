var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "-1 -1 25 12",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Symbols",
            stroke: "none",
            "stroke-width": "2",
            fill: "none",
            "fill-rule": "evenodd",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        },
        [
          _c(
            "g",
            {
              staticClass: "arrow",
              class: { "theme-stroke": _vm.themed },
              attrs: { id: "Icon/Arrow", stroke: "#AAADAD" }
            },
            [
              !_vm.left
                ? _c("g", { attrs: { id: "Arrow/Right" } }, [
                    _c("polyline", {
                      attrs: {
                        id: "Shape",
                        transform:
                          "translate(20.500000, 5.000000) scale(-1, 1) translate(-20.500000, -5.000000) ",
                        points: "23 10 18 5 23 0"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        id: "Shape",
                        d: "M0,5 L17,5",
                        transform:
                          "translate(11.500000, 5.500000) scale(-1, 1) translate(-11.500000, -5.500000) "
                      }
                    })
                  ])
                : _c("g", { attrs: { id: "Arrow/Left" } }, [
                    _c("polyline", {
                      attrs: { id: "Shape", points: "5 10 0 5 5 0" }
                    }),
                    _vm._v(" "),
                    _c("path", { attrs: { id: "Shape", d: "M0,5 L23,5" } })
                  ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }