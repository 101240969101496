<template>
  <p class="bread-crumbs">
    <template v-for="(crumb, index) in options.crumbs">
      <span
        :key="index"
        :class="{ active: index === options.active }"
      >{{ index+1 }}.&nbsp;{{ $t(crumb) | sentence }}</span>
      <span
        :key="index + '_d'"
        v-if="index !== last"
        class="bread-crumbs__delimiter"
      />
    </template>
  </p>
</template>

<script>
export const WsBreadCrumbs = {
  name: "WsBreadCrumbs",
  props: {
    options: {
      type: Object,
      default: {
        crumbs: ["first", "second"],
        active: 0,
      },
    },
  },
  computed: {
    last() {
      return this.options.crumbs.length-1;
    },
  },
};
export default WsBreadCrumbs;
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
  .bread-crumbs {
    color: $text-color-helper;

    .active {
      color: $text-color-dark;
      font-weight: bolder;
    }
  }

  .bread-crumbs__delimiter {
    display: inline-block;
    position: relative;
    margin: 0 2rem;
    height: 1rem;
    width: 4rem;
    top: -0.3rem;
    border-bottom: 0.0625rem solid $text-color-helper;
  }
</style>