<script>
import { BButton } from "bootstrap-vue";

export const WsButton = {
  name: "WsButton",
  extends: BButton,
};
export default WsButton;
</script>

<style lang="scss">
@import "bootstrap/scss/type";
@import "bootstrap/scss/buttons";
</style>
<style lang="scss" scoped>
$border: 0.15rem solid rgba(0, 0, 0, 0.2);
button, a.btn {
  border: 0;
  border-bottom: $border;
  letter-spacing: $letter-spacing-btn;
  padding-bottom: $input-btn-padding-bottom-lg;

  &:hover,
  &:active,
  &:disabled {
    border-bottom: $border;
  }
  &:disabled {
    cursor: not-allowed;
  }

  // forcing style
  &.btn-primary:not(:disabled):not(.disabled):active {
    border-bottom: $border;
  }
}
</style>
