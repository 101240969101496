/*
 * This is webpack bundle entry point for whole client app
 */

import 'client/scss/app.scss';

import Vue from 'vue';
import env from 'env';
import { getQueryParams } from './utilities';
import { config } from './services/config';
import { service as i18nService, i18n } from './services/i18n';
import recaptcha from './services/recaptcha';
import errorService from './services/error';
import store from './store';
import { router, routerUtils } from './router';
import './filters';
import AppFrame from './components/frame/AppFrame';

if (env.isBrowser) {
  window.WS_AuthService = { buildNumber: env.buildNumber };
}

new Vue({
  el: '.ws-app-login',
  router,
  store,
  i18n,
  components: { AppFrame },
  beforeMount: function() {
    // 1. PROCESS BACKEND PROVIDED PARAMS

    const injectedState = {};

    recaptcha.setKeys(config.recaptchaSiteKey, config.recaptchaSiteKeyV2);

    if (config.env) {
      errorService.setEnv(config.env);
      if (env.isBrowser) {
        window.WS_AuthService.enviroment = config.env;
      }
    }

    if (config.currentUser && config.currentUser.login) {
      injectedState.loggedUser = config.currentUser;
      injectedState.activeLogin = config.currentUser.login;
      injectedState.redirectTo = config.currentUser.redirectTo;
    } else {
      config.currentUser = null;
    }

    if (config.company) {
      injectedState.company = config.company;
    }

    const rememberedUsers = (injectedState.rememberedUsers =
      config.rememberedUsers || []);
    if (
      !config.currentUser &&
      rememberedUsers &&
      rememberedUsers.length === 1
    ) {
      // if there is only one saved login and user is not currently logged-in, select the only login
      injectedState.login = rememberedUsers[0].login;
    }
    // Connect context for error reporting
    errorService.setContext(injectedState);

    // 2. PROCESS URL QUERY PARAMS

    let queryParams = {};
    if (env.isBrowser) {
      queryParams = getQueryParams(
        window.location && window.location.href
      );
    }

    injectedState.site = queryParams.s || 'admin';
    injectedState.isHelpdesk = !!queryParams.h;

    this.$store.commit('updateState', injectedState);

    // 3. CONDITIONAL INITIAL ROUTING

    if (config.twoFactorRequired) {
      this.$store.commit('updateState', {
        login: config.currentUser && config.currentUser.login,
      });
      router.push('code/2step');
    } else if (
      env.isBrowser &&
      window.location.pathname === '/' &&
      ((rememberedUsers && rememberedUsers.length > 1) || config.currentUser) &&
      !queryParams['moje-id-error']
    ) {
      router.push('list');
    }
  },
  template: '<app-frame></app-frame>',
});

i18nService.langChanges.subscribe(l => routerUtils.setQueryParams({ l }));

// console log warning for users aka Facebook
/* eslint-disable no-console */
if (env.isProductionBuild) {
  console.log('%cStop!', 'color: red; font-size: 2rem; font-weight: bold;');
  console.log(
    '%cThis is a browser feature intended for developers. If someone told you to copy and paste something here to enable a some feature or "hack" something, it is a scam and will give them access to your account.',
    'font-size: 1rem;'
  );
  console.log(
    '%cSee https://en.wikipedia.org/wiki/Self-XSS for more information.',
    'font-size: 1rem;'
  );
}
/* eslint-enable no-console */
