<template>
  <div class="policy">
    {{ $t('recaptcha_policy.part1') }}
    <a
      :href="'https://policies.google.com/privacy?hl=' + lang"
      class="theme-color"
    >{{ $t('recaptcha_policy.policy_link') }}</a>
    {{ $t('recaptcha_policy.part2') }}
    <a
      :href="'https://policies.google.com/terms?hl=' + lang"
      class="theme-color"
    >{{ $t('recaptcha_policy.terms_link') }}</a>
    {{ $t('recaptcha_policy.part3') }}
  </div>
</template>

<script>
import i18nService from "../services/i18n";
let subscription;

export default {
  name: "RecaptchaPolicy",
  data() {
    return {
      lang: 'en',
    }
  },
  mounted() {
		if (subscription) {
			subscription.unsubscribe();
		}
		subscription = i18nService.langChanges.subscribe(lang => this.lang = lang);
	},
	destroyed() {
		if (subscription) {
			subscription.unsubscribe();
		}
	},
};
</script>