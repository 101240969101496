var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    { attrs: { name: "height", mode: "out-in" } },
    [
      _c(
        "div",
        { key: "edit", staticClass: "input-wrap" },
        [
          _c(
            "ws-float-label",
            { attrs: { label: _vm.$attrs.label, hide: !_vm.editMode } },
            [
              _c(
                "b-form-input",
                _vm._g(
                  _vm._b(
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.editMode,
                          expression: "editMode"
                        }
                      ],
                      ref: "input",
                      attrs: {
                        value: _vm.value,
                        autocomplete: "username",
                        autocapitalize: "off",
                        spellcheck: "false",
                        type: "text",
                        size: "lg"
                      }
                    },
                    "b-form-input",
                    _vm.$attrs,
                    false
                  ),
                  _vm.listeners
                )
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "user-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.editMode,
              expression: "!editMode"
            }
          ],
          key: "saved",
          attrs: { login: _vm.value }
        },
        [
          _c(
            "button",
            {
              staticClass: "edit-btn capitalize username-input-edit-btn",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("edit-start")
                }
              }
            },
            [
              _vm._v("\n      " + _vm._s(_vm.$t("change"))),
              _c("img", {
                staticClass: "edit-icon",
                attrs: { src: _vm.pencilIcon, alt: "icon edit" }
              })
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }