import { render, staticRenderFns } from "./WsBreadCrumbs.vue?vue&type=template&id=03a25f0a&scoped=true&"
import script from "./WsBreadCrumbs.vue?vue&type=script&lang=js&"
export * from "./WsBreadCrumbs.vue?vue&type=script&lang=js&"
import style1 from "./WsBreadCrumbs.vue?vue&type=style&index=1&id=03a25f0a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03a25f0a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03a25f0a')) {
      api.createRecord('03a25f0a', component.options)
    } else {
      api.reload('03a25f0a', component.options)
    }
    module.hot.accept("./WsBreadCrumbs.vue?vue&type=template&id=03a25f0a&scoped=true&", function () {
      api.rerender('03a25f0a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "client/WS_UIkit/WsBreadCrumbs.vue"
export default component.exports