var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    staticClass: "mojeid",
    attrs: { src: _vm.mojeIDIcon, alt: "MojeID" },
    on: { click: _vm.login }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }