<template>
  <div
    v-if="errorList.length"
    class="error-note"
  >
    <div class="icon d-xs-none">
      <div class="hexagon">
        <img
          :src="iconAlert"
          class="hexagon__img"
          alt="alert icon"
        >
      </div>
    </div>
    <div class="message">
      <ul class="message__list">
        <li
          v-for="(error, index) in errorList"
          :key="index"
        >
          <span v-html="xss((error && error.translation) || getTranslation(error) || $t('unknown_error'))"></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import iconAlert from "client/images/Icon_alert-poly.svg";
import xss from "xss";

export default {
  name: "ErrorNote",
  props: {
    errorList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      iconAlert,
      xss,
    }
  },
  methods: {
    getTranslation(error) {
      if (!error || !error.tKey) {
        return null;
      } // else
      const translated = this.$t(error.tKey, _execFnArgs(error.tArgs));
      if (translated === error.tKey) {
        // did not found translation
        return null;
      } // else
      return translated;
    },
  },
};

function _execFnArgs(args) {
  if (typeof args !== 'object') {
    return args;
  }
  const executed = {};
  Object.keys(args).forEach(key => {
    if (typeof args[key] === "function") {
      executed[key] = args[key]();
    }
  });
  return { ...args, ...executed };
}
</script>

<style lang="scss">
@import "bootstrap/scss/utilities/display";
</style>

<style lang="scss" scoped>
$c-color: $white;

.error-note {
  display: flex;
  margin: 1rem 0;
  color: $red;
}

.icon {
  position: relative;
  width: 3rem;
  margin-right: 1rem;
  flex-shrink: 0;
}

.message {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.message__list {
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0;
}

.d-xs-none {
  @media (max-width: 20rem) {
    display: none;
  }
}
</style>